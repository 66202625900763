import React, { useState, useEffect } from "react";

import { Container, Header, Content, Row, Col, Steps, Icon, Input, InputGroup, Message, SelectPicker, Button, Footer, Placeholder, Loader, Checkbox, Radio, RadioGroup, Notification, DatePicker } from 'rsuite';

import { useParams, useHistory } from "react-router-dom";

import InputMask from "react-input-mask";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import logo from '../../assets/svg/d4pay_full_color.svg'
import lockpick from '../../assets/svg/lockpick.svg'

import mastercard from '../../assets/svg/card_brands/mastercard-bgblack.svg'
import visa from '../../assets/svg/card_brands/visa.svg'
import amex from '../../assets/svg/card_brands/american-express.svg'
import elo from '../../assets/svg/card_brands/elo-bgblack.svg'
import diners from '../../assets/svg/card_brands/dinersclub.svg'
import boleto from '../../assets/svg/card_brands/boleto.svg'
import boleto_grande from '../../assets/svg/boleto.svg'
import pix from '../../assets/svg/card_brands/pix.svg'
import pix_grande from '../../assets/svg/pix_logo_grande.svg'

import br_flag from '../../assets/svg/br.svg'
import us_flag from '../../assets/svg/us.svg'
import es_flag from '../../assets/svg/es.svg'

import pac_correios from "../../assets/img/pac-correios-logo-8.png"
import sedex_correios from "../../assets/img/sedex-logo-7.png"

import norton from '../../assets/svg/norton-secured.svg'
import sslshopper from '../../assets/svg/ssl-shopper.svg'
import googlesafe from '../../assets/svg/google-safebrowsing.svg'

import generateArrayOfYears from '../../utils/years';
import { validaCpfCnpj, sanitize } from "../Register/components/utils";
import Card from '../../components/Card.component'
import FieldValidator from "../../components/FieldValidator.component";
import monthData from '../../utils/month';

import CepService from "../../services/ViaCep.service";
import IuguService from '../../services/Iugu.service.js'
import ProductService from "../../services/Product.service";
import OrderService from "../../services/Order.service";
import SubscriptionService from "../../services/Subscription.service";
import DataLayerService from "../../services/DataLayer.service";
import CheckoutService from "../../services/Checkout.service";

import OrderBump from '../../components/OrderBump.component'
import CartSessionService from "../../services/CartSession.service";
import formatMoney from "../../utils/formatMoney.util";

import sanitizeString from "../../utils/sanitizeString.util"

import { useTranslation } from 'react-i18next';

import './translation/i18n';
import { capitalizeString } from "../../utils/CapitalizeString.util.js";

import parseDateTime  from "../../utils/parseDateTime.js"

import dayjs from 'dayjs';

import { es, pt } from 'date-fns/locale';
// const CryptoJS = require("crypto-js");


const CheckoutPage = (props) => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        setActiveLanguage(lng)
        i18n.changeLanguage(lng);
    };

    const isMobile = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }


    const [activeLanguage, setActiveLanguage] = useState("pt")


    const getDatePickerLocale = () => {
        switch (activeLanguage) {
            case 'en':
                return null
            case 'es':
                return es
            case 'pt':
                return pt
            default:
                return pt
        }
    }
    
    const { shortUrl, affiliateCode } = useParams();
    
    const getQueryVariableObject = () => {
        const obj = {}
        const searchString = window.location.hash
            .replace('nome','name')
            .substring(window.location.hash.indexOf('?')+1)
            .split('&');

        searchString.map(param => {
            if(param){
                const paramSplited = param.split('=')
                if(paramSplited[0] === "name"){
                    paramSplited[1] = paramSplited[1].replaceAll('+', ' ')
                }
                obj[paramSplited[0]] = decodeURIComponent(paramSplited[1])
            }
        })
        return obj
    }

    const history = useHistory();

    const dataLayerService = new DataLayerService()

    const yearData = generateArrayOfYears();

    const [paymentMethod, setPaymentMethodState] = useState("pix");

    const [cartState, setCartState] = useState({
        loading: false,
        acceptedOrderBump: false,
        shipppingQuoteData: [],
        installmentsList: [],
        // installmentWithShipping: [],
        // installmentWithShippingAndOrderBump: [],
        selectedShippingId: null,
        quoteCart: {
            cartOffers: [],
            items: [], //cart items will be stored here
            acceptedOrderBump: false, //default state
            zipCode: "", //default state
            selectedInstallment: 1, //default state
            selectedShippingId: null, //default state,
            shippingOptions: [],
            checkoutUrl: []
        }
    })

    const incrementCartItemQuantity = (id) => {
        const newCartOfferIncremented = cartState?.quoteCart?.items?.map(item => {
            if(item.offer._id === id){
                item.quantity = item.quantity + 1
            }

            return item
        })

        setCartState({...cartState, quoteCart: {...cartState.quoteCart, items: newCartOfferIncremented}})

        quoteCart({})
    }


    const decrementCartItemQuantity = (id) => {
        const newCartOfferIncremented = cartState?.quoteCart?.items.map(item => {
            if(item.offer._id === id){
                if(item.quantity > 0){
                    item.quantity = item.quantity - 1
                }
            }

            return item
        })
        setCartState({...cartState, quoteCart: {...cartState.quoteCart, items: newCartOfferIncremented}})
        quoteCart({})
    }

    // useEffect(()=>console.log(cartState), [cartState])

    const [validator, setValidator] = useState({
        name: {
            hasError: false,
            message: ""
        },
        email: {
            hasError: false,
            message: ""
        },
        cpf: {
            hasError: false,
            message: ""
        },
        phone: {
            hasError: false,
            message: ""
        },
        zipcode: {
            hasError: false,
            message: ""
        },
        addressNumber: {
            hasError: false,
            message: ""
        },
        // city: {
        //     hasError: false,
        //     message: ""
        // },
        // uf: {
        //     hasError: false,
        //     message: ""
        // },
        cardHolder: {
            hasError: false,
            message: ""
        },
        creditcard: {
            hasError: false,
            message: ""
        },
        expireMonth: {
            hasError: false,
            message: ""
        },
        expireYear: {
            hasError: false,
            message: ""
        },
        cvv: {
            hasError: false,
            message: ""
        },
        splitMonths: {
            hasError: false,
            message: ""
        },
    });

    const [formState, setFormState] = useState({
        name: "",
        email: "",
        cpf: "",
        phone: "",
        zipcode: "",
        street: "",
        neighborhood: "",
        addressNumber: "",
        city: "",
        uf: "",

        creditcard: "",
        expireMonth: "",
        expireYear: "",
        cvv: "",
        splitMonths: 1,
        bookDate: "",
        // customForm: {}
    })

    const [checkoutState, setCheckoutState] = useState({
        step: 0,
        state: {}
    })

    const [loading, setLoading] = useState(false)
    const [appliedCoupom, setAppliedCoupom] = useState(null)
    const [creditCardError, setCreditCardError] = useState({hasError: false, message: ""})
    const [numberDisabled, setNumberDisabled] = useState(true)

    const [addressFieldsDisabled, setAddressFieldsDisabled] = useState({
        zipcode: false,
        address: true,
        number: true,
        complement: true,
    })

    const [creditCardBrand, setCreditCardBrand] = useState("")
    const [productState, setProductState] = useState(props.productMetadata || {})
    const [internationalResident, setInternationalResident] = useState(false)

    const toggleInternationalResident = () => {
        setInternationalResident(!internationalResident)

        if(!internationalResident){
            setAddressFieldsDisabled({...addressFieldsDisabled, number: false, address: false, zipcode: true, complement: false})
            setFormState({...formState, zipcode: "00000-000"})
        }else{
            setAddressFieldsDisabled({...addressFieldsDisabled, number: true, address: false, zipcode: false, complement: false})
            setFormState({...formState, zipcode: ""})
        }
    }

    const setPaymentMethod = (method) => {

        // dataLayerService.pushCheckoutMetric('change_payment_method', {previous: paymentMethod, next: method})
        setPaymentMethodState(method)

        if(method === "pix"){
            quoteCart({
                selectedInstallment: 1, 
                paymentMethod: method
            })
            setFormState({
                ...formState, 
                creditcard: "pix",
                expireMonth: "pix",
                expireYear: "pix",
                cvv: "pix",
                paymentPlan: 'direct',
                splitMonths: 1
            })
        }

        if(method === "boleto"){
            quoteCart({
                selectedInstallment: 1, 
                paymentMethod: method
            })
            setFormState({
                ...formState, 
                creditcard: "boleto",
                expireMonth: "boleto",
                expireYear: "boleto",
                cvv: "boleto",
                paymentPlan: 'direct',
                splitMonths: 1
            })
        }

        if(method === "credit"){
            quoteCart({
                selectedInstallment: 1, 
                paymentMethod: method
            })
            setFormState({
                ...formState, 
                creditcard: "",
                expireMonth: "",
                expireYear: "",
                cvv: "",
                paymentPlan: 'direct'
            })
        }
    }

    const quoteCart = async (payload) => {
        const orderBumpNextValue = payload?.overrideOrderBumpState ? payload?.acceptedOrderBump : cartState?.acceptedOrderBump
        setCartState({
            ...cartState, 
            selectedShippingId: payload?.selectedShippingId || cartState?.selectedShippingId,
            acceptedOrderBump: orderBumpNextValue,
            loading: true,
        })

        const response = await new CheckoutService().post({
            cartOffers: payload?.cartOffers || cartState?.quoteCart?.items,
            acceptedOrderBump: orderBumpNextValue,
            zipCode: payload?.zipCode || formState?.zipcode,
            selectedInstallment: payload?.selectedInstallment || formState?.splitMonths,
            selectedShippingId: payload?.selectedShippingId || cartState?.selectedShippingId,
            checkoutUrl: payload?.checkoutUrl || productState?.productOffer?.checkout_url,
            coupom: payload?.coupom || appliedCoupom,
            paymentMethod: payload?.paymentMethod || paymentMethod, 
            fixedOffer: payload?.fixedOffers || productState?.product?.fixedOffers?.find(offer => offer.id === formState?.fixedOffer),
        }, 'quote')

        if(response?.quoteCart?.shippingOptions?.length === 1){
            payload.selectedShippingId = response?.quoteCart?.shippingOptions[0].id
        }

        setCartState({
            ...cartState, 
            acceptedOrderBump: orderBumpNextValue,
            selectedInstallment: payload?.selectedInstallment || formState?.splitMonths,
            selectedShippingId: payload?.selectedShippingId || cartState?.selectedShippingId,
            quoteCart: response,
            loading: false,
        })
    }

    const loadProductData = async () => {
        document.title = "Finalizar Compra"
        const productService = new ProductService();
        const response = await productService.get(`getbyurl/${shortUrl || props.shortUrl}`)

        if(response?.error || response?.length === 0){
            history.push('/404')
        }else{
            if(!response.product.payableWith.credit){
                if(response.product.payableWith.boleto)
                    setPaymentMethod('boleto')
                if(response.product.payableWith.pix)
                    setPaymentMethod('pix')
            }

            document.title = `${response.productOffer.name} - ${t('Finalizar Compra')}`

            dataLayerService.setupGTag(response.product.pixelSettings.googleAds.map(setting => setting.pixelId))
            dataLayerService.setupFbq(response.product.pixelSettings.facebook.map(setting => setting.pixelId))

            dataLayerService.pushCheckoutView('checkout_view', {product_name: response.productOffer.name})
            
            if(!process.env.REACT_APP_PROD){
                console.log('!!!!!!!!!!!!!!!!!!IUGU IN TEST MODE!!!!!!!!!!!!!!!!')
                window.Iugu.setAccountID("E3FD1606FAA946BFBEEAD53D26AB1792");
                window.Iugu.setup();
                window.Iugu.setTestMode(true);
            }else{
                window.Iugu.setAccountID("E3FD1606FAA946BFBEEAD53D26AB1792");
                window.Iugu.setup();
            }

            setProductState({...response})

            let productOffers = [{
                quantity: 1,
                offer: response?.productOffer
            }]

            if(response?.product?.showAllOffersInCheckout){
                productOffers = response?.product?.productOffers.map((offer, index) => {
                    return {
                        quantity: index === 0 ? 1 : 0,
                        offer
                    }
                })
            }

            console.log("productOffers", productOffers)

            const firstQuotePayload = {
                cartOffers: productOffers,
                acceptedOrderBump: false, //default state
                zipCode: "", //default state
                selectedInstallment: 1, //default state
                selectedShippingId: null, //default state
                checkoutUrl: response?.productOffer?.checkout_url
            }

            await quoteCart(firstQuotePayload)

            let customFieldsFormStatePayload = {}
            let customValidatorStatePayload = {}
            response?.product?.customFields.forEach((customField) => {
                // console.log('foreach customField', customField)
                const field = customField.fieldType === "select_picker" ? sanitizeString(customField.fieldName) : customField.fieldUrlParam
                customFieldsFormStatePayload = {...customFieldsFormStatePayload, [field?.toLocaleLowerCase()]: ""}
                customValidatorStatePayload = {...customValidatorStatePayload, [field?.toLocaleLowerCase()]: {  hasError: false, message: "" }}
            })

            const formData = await loadFormFromString(firstQuotePayload)

            if(formData.bookDate){
                const date = formData.bookDate.split('-')
                const month = date[0]
                const day = date[1]
                const year = date[2]

                formData.bookDate = new Date(year, month-1, day)
            }

            let fixedOfferValidator = {}

            if(response?.product?.enableFixedOffer){
                console.log("entrou aquiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
                fixedOfferValidator = {
                    fixedOffer: {hasError: false, message: ""},
                }
            }
            console.log(fixedOfferValidator)
            setFormState({...formState, customForm: customFieldsFormStatePayload, ...formData})
            setValidator({...validator, ...customValidatorStatePayload, ...fixedOfferValidator})
        }

    }

    const loadFormFromString = async (firstQuotePayload) => {
        const searchStringObj = getQueryVariableObject()

        let payload = {}
        let cartOffersPayload = firstQuotePayload.cartOffers
        let productsModified = false

        Object.keys(searchStringObj).map(async (key) => {
            if(key.includes('product_')){
                const product_id = key.split("product_")[1]
                const quantity = searchStringObj[key]
    
                cartOffersPayload = cartOffersPayload.map(cartOffer => {
                    if(cartOffer.offer._id === product_id){
                        cartOffer.quantity = parseInt(quantity)
                    }

                    productsModified = true
    
                    return cartOffer
                })
            }
        })

        await Promise.all(await Object.keys(searchStringObj).map(async (key) => {
            if(Object.keys(formState).includes(key) || key.includes('custom_')){
                if(key === "zipcode" && searchStringObj[key].length >= 8){ //8 because is unmasked
                    // console.log(key, 'searchStringObj[key]', searchStringObj[key])
                    const cepData = await getCepAddress(searchStringObj[key], true)
                    payload = {...payload, ...cepData}
                }else{
                    // console.log(key, searchStringObj[key])
                    if(key.includes('custom_')){
                        payload = {...payload, customForm: {...formState.customForm, [key.split('custom_')[1]?.toLocaleLowerCase()]: searchStringObj[key] }}
                    }else{
                        payload = {...payload, [key]: searchStringObj[key]}
                    }
                }

                // console.log('loadFormFromString', payload)
            }
        }))

        if(productsModified){
            quoteCart({
                ...firstQuotePayload,
                cartOffers: cartOffersPayload,
            })
        }

        console.log('loadFormFromString', payload)
        return payload

    }

    const setLanguageByButton = (lang) => {
        localStorage.setItem('language', lang)
        changeLanguage(lang)
    }

    const loadDefaultLanguage = () => {
        let navigatorLanguage = localStorage.getItem('language')
        
        if(!navigatorLanguage){
            navigatorLanguage = navigator.language.toLowerCase().split('-')[0];
        }

        switch (navigatorLanguage) {
            case 'en':
                changeLanguage('en')
                break;
            case 'es':
                changeLanguage('es')
                break;
            case 'pt':
                changeLanguage('pt-BR')
                break;
            default:
                changeLanguage('pt-BR')
        }
    }

    useEffect(() => {
        // loadFormFromString()
        loadDefaultLanguage()
        loadProductData()
    }, [])


    const toggleShippingMethod = (value) => {
        quoteCart({selectedShippingId: value})
    }

    const [priceLoading, setPriceLoading] = useState(false)

    const getCepAddress = async (cep, silent = false) => {
        const cepService = new CepService();
            try{
                const cepResponse = await cepService.find(cep);

                //console.log("productType ", productState.product.productType)
                if(productState?.product?.productType === "physical" || productState?.orderBump?.productType === "physical" ){
                    quoteCart({zipCode: cep})
                }

                if(cepResponse && !cepResponse.erro) {
                    const payload = {
                        zipcode: cep,
                        street: cepResponse.logradouro ? `${cepResponse.logradouro}, ${cepResponse.localidade} - ${cepResponse.uf}` : "", 
                        addressName: cepResponse.logradouro || "", 
                        city: cepResponse.localidade || "", 
                        neighborhood: cepResponse.bairro || "",  
                        uf: cepResponse.uf || ""
                    }



                    if(!silent){
                        setFormState({...formState, ...payload})
                        setValidator({...validator, zipcode: {hasError: false, message: null}})
                        setAddressFieldsDisabled({...addressFieldsDisabled, number: false, complement: false})

                    }else{
                        setAddressFieldsDisabled({...addressFieldsDisabled, number: false, complement: false})
                        return payload
                    }


                    if(!payload.logradouro || !payload.uf){
                        setAddressFieldsDisabled({number: false, address: false, zipcode: false, complement: false})
                    }

                }else{
                    // alert("aqui")
                    setAddressFieldsDisabled({...addressFieldsDisabled, number: true, complement: false})
                    setValidator({...validator, zipcode: {hasError: true, message: t('Não conseguimos encontrar o CEP informado.')}})
                }
            }catch(e){
                console.log(e)
                setAddressFieldsDisabled({...addressFieldsDisabled, number: true})
                setValidator({...validator, zipcode: {hasError: true, message: t('Não conseguimos encontrar o CEP informado.')}})
            }
    }

    const formValidations = (field, value, required = true, custom = false) => {
        const regexEmail = /\S+@\S+\.\S+/;
        let payload = {}
        //console.log(field, value, required)
        // if(field.includes("customForm")){
        //     if(required){
        //         if(!value){
        //             payload = {...payload, [field]: {hasError: true, message: "Você deve preencher este campo"}}
        //         }else {
        //             payload = {...payload, [field]: {hasError: false, message: null}}
        //         }
        //     }
        // }

        if(field){
            // if(!value && required && (!internationalResident))
            //     payload = {...payload, [field]: {hasError: true, message: "Você deve preencher este campo"}}
            // else
                payload = {...payload, [field]: {hasError: false, message: null}}
            
            if(value){
                payload = {...payload, [field]: {hasError: false, message: null}}
            }


            if(field.includes("custom_")){
                if(required){
                    if(!value){
                        payload = {...payload, [field]: {hasError: true, message: t('Você deve preencher este campo')}}
                    }else{
                        payload = {...payload, [field]: {hasError: false, message: null}}
                    }
                }
            }

            if(value && !regexEmail.test(value) && field === "email")
                payload = {...payload, email: {hasError: true, message: t('Informe um e-mail válido')}}
        }

        if(field === "phone"){
            if(sanitize(value).length < 11){
                payload = {...payload, [field]: {hasError: true, message: t('Informe um telefone válido')}}
            }
        }

        if(field === "cardHolder"){
            if(value.split(" ").length < 2){
                payload = {...payload, [field]: {hasError: true, message: t('Informe o nome e sobrenome do titular do cartão')}}
            }
        }

        if(field === "name"){
            if(value.split(" ").length < 2){
                payload = {...payload, name: {hasError: true, message: t('Informe o nome completo')}}
            }
        }

        if(field === "addressNumber"){
            if(!value.length){
                payload = {...payload, [field]: {hasError: true, message:  t('Campo Obrigatório')}}
            }else{
                payload = {...payload, [field]: {hasError: false, message: ""}}
            }
        }

        if(field === "zipcode"){
            if(sanitize(value).length === 8){
                if(formState.zipcode !== value)
                    getCepAddress(value)
            }else{
                payload = {...payload, [field]: {hasError: true, message: t('Você deve informar um CEP válido')}}

                setNumberDisabled(true)
            }
        }

        if(field === "cpf" && !internationalResident){
            if(value){
                const data = validaCpfCnpj(value)
                // //console.log('data', data)
                if(!data)
                    payload = {...payload, [field]: {hasError: true, message: `${t('Informe um')} ${value.length < 15 ? t('CPF') : t('CNPJ')} ${t('válido')}`}}
                else
                    payload = {...payload, [field]: {hasError: false, message: null}}
            }else{
                payload = {...payload, [field]: {hasError: true, message: `${t('Informe um')} ${value.length < 15 ? t('CPF') : t('CNPJ')} ${t('válido')}`}}
            }
        }

        if(paymentMethod === "credit"){
            const iuguService = new IuguService()
    
            if(field === "creditcard"){
                const validCreditCardNumber = iuguService.validateCreditCardNumber(value)
                if(validCreditCardNumber){
                    payload = {...payload, [field]: {hasError: false, message: null}}
                    
                    const brand = iuguService.getBrandByCreditCardNumber(value)
                    // //console.log('brand', brand)
                    if(brand){
                        setCreditCardBrand(brand)
                    }else{
                        payload = {...payload, [field]: {hasError: true, message: t('Não aceitamos a bandeira informada')}}
                    }
                }else{
                    payload = {...payload, [field]: {hasError: true, message: t('Digite um número de cartão válido')}}
                }
            }
    
            if(field === "expireMonth"){
                if(!value){
                    payload = {...payload, [field]: {hasError: true, message: t('Selecione o mês de vencimento')}}
                }else{
                    const validExpire = iuguService.validateCreditCardExpiration(value, formState.expireYear)
    
                    if(!validExpire)
                        payload = {...payload, expireMonth: {hasError: true, message: t('Vencimento inválido')}, expireYear: {hasError: true, message: t('Vencimento inválido')}}
                    else
                        payload = {...payload, expireMonth: {hasError: false, message: null}, expireYear: {hasError: false, message: null}}
    
                }
            }
    
            if(field === "expireYear"){
                if(!value){
                    payload = {...payload, [field]: {hasError: true, message: t('Selecione o ano de vencimento')}}
                }else{
                    payload = {...payload, [field]: {hasError: false, message:null}}
    
                    if(formState.expireMonth && value){
                        const validExpire = iuguService.validateCreditCardExpiration(formState.expireMonth, value)
    
                        if(!validExpire){
                            payload = {...payload, expireMonth: {hasError: true, message: t('Vencimento inválido')}, expireYear: {hasError: true, message: t('Vencimento inválido')}}
                        }else{
                            payload = {...payload, expireMonth: {hasError: false, message: null}, expireYear: {hasError: false, message: null}}
                        }
                    }
                }
            }
    
            if(field === "cvv"){
                if(!value){
                    payload = {...payload, [field]: {hasError: true, message: t('Você deve preencher este campo')}}
                }else{
                    if(formState.creditcard){
                        const validCvv = iuguService.validateCreditCardCvv(value, creditCardBrand)
                        if(validCvv)
                        payload = {...payload, [field]: {hasError: false, message:null}}
                        else
                        payload = {...payload, [field]: {hasError: true, message:t('CVV Inválido')}}
                    }else{
                        payload = {...payload, [field]: {hasError: false, message:null}}
                    }
                }
            }
        }

        if(cartState?.quoteCart?.shippingOptions?.length){
            if(!cartState.selectedShippingId){
                payload = {...payload, "shipping": {hasError: true, message:t('Você deve escolher o método de envio')}}
            }else{
                payload = {...payload, "shipping": {hasError: false, message: null}}
            }
        }else{
            payload = {...payload, "shipping": {hasError: false, message: null}}
        }

        if(cartState?.quoteCart?.shippingOptions.length){
            if(!cartState.selectedShippingId){
                payload = {...payload, "shipping": {hasError: true, message:t('Você deve escolher o método de envio')}}
            }else{
                payload = {...payload, "shipping": {hasError: false, message: null}}
            }
        }else{
            payload = {...payload, "shipping": {hasError: false, message: null}}
        }

        if(!cartState?.quoteCart?.installments?.find(installment => installment.value === formState.splitMonths)) {
            payload = {...payload, "splitMonths": {hasError: true, message: t('Selecione a quantidade de parcelas')}}
        }else {
            payload = {...payload, "splitMonths": {hasError: false, message: null}}
        }

        if(field === "bookDate" && productState?.product?.productType === "booking"){
            if(!value || !isValidDate(value)){
                payload = {...payload, [field]: {hasError: true, message: t('Você precisa informar a data da reserva')}}
            }else{
                payload = {...payload, [field]: {hasError: false, message: null}}
            }
        }

        if(field === "fixedOffer" && productState?.product?.enableFixedOffer){
            if(!value){
                payload = {...payload, [field]: {hasError: true, message: t('Você escolher uma das opções')}}
            }else{
                payload = {...payload, [field]: {hasError: false, message: null}}
            }
        }

        return payload
    }


    function isValidDate(dateString) {
        const date = new Date(dateString);
        
        return !isNaN(date.getTime());
    }

    const formPostValidation = () => {
        let fields = Object.keys({...formState, ...formState.customForm})
        // console.log("fields: ", fields)
        let payload = {}

        if(productState?.product?.productType === "booking"){
            fields = [...fields, "bookDate"]
        }
        if(productState?.product?.enableFixedOffer === true){
            fields = [...fields, "fixedOffer"]
        }
        

        fields.map(field => {
            let value = formState[field]
            // //console.log(`${field} value: `, value)
            let required = true
            if(field.includes("custom_")){
                required = productState?.product?.customFields.find(_field => _field.fieldUrlParam === field.replace("custom_", ""))?.required ? true : false
                value = formState.customForm[field.replace("custom_", "")]
            }
            payload = {...payload, ...formValidations(field, value, required)}
        })

        setValidator({...validator, ...payload})

        console.log('payload', payload)

        return !Object.values(payload).map(item => item.hasError).includes(true)
    }

    const getSelectedShipping = () => {
        const selectedShipping = cartState?.quoteCart?.shippingOptions.find(quote => quote.id === cartState.selectedShippingId)
        return selectedShipping
    }

    const [orderIdentifier, setOrderIdentifier] = useState(null)

    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const handleFixedOfferChange = (value) => {
        handleFormChange("fixedOffer", value, true); 
    }

    useEffect(() => {
        if(formState?.fixedOffer){
            quoteCart({fixedOffer: productState?.product?.fixedOffers?.find(offer => offer.id === formState?.fixedOffer)})
        }
    }, [formState?.fixedOffer])

    const formClick = async () => {
        handleCartSession()
        const validated = formPostValidation()
        // setCheckoutState({step: checkoutState.step, state: {}})
        setCreditCardError({hasError: false, message: ""})
        
        // console.log('formState', formState)

        // //console.log(validated)

        if(validated) {
            try{
                const time = Date.now()
                dataLayerService.pushCheckoutMetric('payment_intent', {method: paymentMethod, product: productState.product.name, url: window.location.hash, value: productState.productOffer.price})
                //ok
                const checkoutService = new CheckoutService();
                let payload = {
                    "cart": {
                        "cartOffers": cartState?.quoteCart?.items,
                        "acceptedOrderBump": formState.acceptedOrderBump,
                        "zipCode": formState.zipcode,
                        "selectedInstallment": formState?.splitMonths || 1,
                        "selectedShippingId": cartState?.selectedShippingId,
                        
                        "checkoutUrl": shortUrl, 
                        "paymentMethod": "pix", // will be validate below
                        "fixedOffer": productState?.product?.fixedOffers?.find(offer => offer.id === formState?.fixedOffer) || null,
                        ...appliedCoupom ? {coupom: appliedCoupom} : {},
                    },
                    "customer": {
                        "name": formState?.name,
                        "email": formState?.email,
                        "cpf_cnpj": formState?.cpf,
                        "phone": formState?.phone,
                        "zip_code": formState?.zipcode,
                        "street": formState?.street,
                        "neighborhood": formState?.neighborhood,
                        "addressNumber": formState?.addressNumber,
                        "city": formState?.city,
                        "uf": formState?.uf,
                        "addressName": formState?.addressName
                    },
                    "cart_session": {},
                    "metadata": {
                        "timestamp": time,
                        "cardData": {
                            "rawCardHolder": formState?.cardHolder,
                            "rawCardNumber": formState?.creditcard,
                            "rawValidMonth": formState?.expireMonth,
                            "rawValidYear": formState?.expireYear,
                            "rawCvv": formState?.cvv
                        },
                        // "hash": CryptoJS.AES.encrypt(JSON.stringify({}), shortUrl+time).toString(),
                        "bookDate": formState?.bookDate,
                        "affiliateCode": affiliateCode || null,
                        "customForm": formState?.customForm || {},
                        "metrics": getQueryVariableObject() || {},
                        internationalResident,
                    }
                }
    
                payload.acceptedOrderBump = cartState.acceptedOrderBump
                payload.selectedShippingService = cartState.selectedShippingId
    
                if(cartSession){
                    payload.cart_session = cartSession
                }else{
                    payload.cart_session = await new CartSessionService().post({email: formState.email, product: productState.product._id, offer: productState.productOffer._id},'save', true)
                }
                
                if(affiliateCode || props.affiliateCode){
                    payload['affiliateCode'] = affiliateCode || props.affiliateCode
                }
            
                let response;

                switch (paymentMethod) {
                    case "credit":
                        //https://support.iugu.com/hc/pt-br/articles/212456346-Usar-cart%C3%B5es-de-teste-em-modo-de-teste
                        // const iuguService = new IuguService();
    
                        // const formObject = document.querySelector('form[name="credit_card_form"]')
                        // const cardToken = await iuguService.createCreditCardToken(formObject)
                        //console.log('cardToken', cardToken)
                        
                        payload.cart.paymentMethod = "credit_card"
                    break;
                    case "pix":
                        payload.cart.paymentMethod = "pix"
    
                    break;
                    case "boleto":
                        payload.cart.paymentMethod = "bank_slip"
    
                    break;
                    default:
                    break;
                }
    
                // delete payload["creditcard"]
                // delete payload["expireMonth"]
                // delete payload["expireYear"]
                // delete payload["cvv"]
    
                if(orderIdentifier){
                    payload.order_id = orderIdentifier
                }
                // console.log('payload', payload)
                setLoading(true)
                response = await checkoutService.post(payload, productState.product.paymentType === "subscription" ? "subscription/process" : "payment/process", true)
                
                // console.log(response)
                // if(paymentMethod === "credit" && response.success === true && productState.product.approvedUrl) {
                //     window.location = `${productState.product.approvedUrl}?ecom_token=${encodeURIComponent(response.oneClickSellToken)}`
                // }
                const metric_payload = {method: paymentMethod, product: productState.product.name, url: window.location.hash, value: productState.productOffer.price }          
    
                switch(paymentMethod){
                    case "credit":
                        if(response.success){
                            if(!response.error){
                                dataLayerService.pushCheckoutMetric('checkout_finished', metric_payload)
                
                                if(productState.product.pixelSettings.googleAds.pushOnApprove){
                                    dataLayerService.pushConversion({
                                        transaction_id: response.order_id,
                                        value: productState.productOffer.price
                                    }, productState.product.pixelSettings.googleAds.map(setting => setting.pixelId))
                                }
                            } 
                            if(productState.product.approvedUrl){
                                let url = productState.product.approvedUrl
                                url = url.startsWith('http') ? url : `http://${url}`
    
                                let url_payload = {
                                    product_name: productState?.product?.name,
                                    customer_name: formState?.name,
                                    email: formState?.email,
                                    cpf: formState?.cpf,
                                    phone: formState?.phone,
                                    zipcode: formState?.zipcode,
                                    street: formState?.street,
                                    neighborhood: formState?.neighborhood,
                                    addressNumber: formState?.addressNumber,
                                    city: formState?.city,
                                    uf: formState?.uf,
                                    ecom_token: response?.oneClickSellToken ? encodeURIComponent(response?.oneClickSellToken) : "",
                                    ...formState?.customForm
                                }
    
                                for (const [key, value] of Object.entries(url_payload)) {
                                    url_payload[key] = encodeURIComponent(value)
                                }
    
                                const redirect_url = `${url}?${new URLSearchParams(url_payload).toString()}`
                                // console.log('redirect_url', redirect_url)
                                window.location = redirect_url
                            }
                            else{
                                setLoading(false)
    
                                setCheckoutState({
                                    step: 1,
                                    state: {
                                        payment_method: paymentMethod,
                                        ...response
                                    }
                                })
                            }
                        }else{
                            dataLayerService.pushCheckoutMetric('payment_failed', metric_payload)
    
                            setCreditCardError({hasError: true, message: t("Não foi possível realizar o pagamento usando este cartão de crédito."), extra: response.message || t("Tente novamente utilizando outro cartão ou forma de pagamento")})
                            setLoading(false)
                        }
                    break;
                    case "pix":
                        if(!response.error){
                            dataLayerService.pushCheckoutMetric('checkout_finished', metric_payload)
            
                            if(productState.product.pixelSettings.googleAds.pushOnApprove){
                                dataLayerService.pushConversion({
                                    transaction_id: response.order_id,
                                    value: productState.productOffer.price
                                }, productState.product.pixelSettings.googleAds.map(setting => setting.pixelId))
                            }
                        }
    
                        setLoading(false)
                        setCheckoutState({
                            step: 1,
                            state: {
                                payment_method: paymentMethod,
                                order_id: response.order_id,
                                ...response
                            }
                        })
                    break;
                    case "boleto":
                        if(!response.error){
                            dataLayerService.pushCheckoutMetric('checkout_finished', metric_payload)
    
                            if(productState.product.pixelSettings.googleAds.pushOnApprove){
                                dataLayerService.pushConversion({
                                    transaction_id: response.order_id,
                                    value: productState.productOffer.price
                                }, productState.product.pixelSettings.googleAds.map(setting => setting.pixelId))
                            }
    
                            if(productState.product.pixelSettings.googleAds.pushOnIssueBoleto){
                                dataLayerService.pushConversion({
                                    transaction_id: response.order_id,
                                    value: productState.productOffer.price
                                }, productState.product.pixelSettings.googleAds.map(setting => setting.pixelId))
                            }
                        }
                        
                        setLoading(false)
                        setCheckoutState({
                                    step: 1,
                                    state: {
                                        payment_method: paymentMethod,
                                        ...response
                                    }
                                })
                    break;
                    default: 
                    break;
                }
            }catch(ex){
                setCreditCardError({hasError: true, message: t('Não foi possível realizar a compra'), extra: ex.message || ""})
                console.log(ex)
                handleCartSession('exception', ex)
                // Notification["error"]({
                //     title: "Não foi possível realizar a compra",
                //     description: <p style={{ width: 320 }} rows={3} >Preencha todas as informações obrigatórias dos campos. {ex.message}</p>
                // });
            }
        }else{
            Notification["error"]({
                title: t('Não foi possível realizar a compra'),
                description: <p style={{ width: 320 }} rows={3} >{t('Preencha todas as informações obrigatórias dos campos.')}</p>
            });
        }
    };

    const copyToClipBoard = (copyText) => {
        navigator.clipboard.writeText(copyText);
    }

    const handleClickVerifyPaymentPix = () => {
        setCheckoutState({
            ...checkoutState,
            step: checkoutState.step === 1 ? 2 : 1,
        })
    }

    useEffect(()=>{
        // //console.log(checkoutState)
        if(checkoutState?.step === 1 && checkoutState?.state?.payment_method === "pix" && checkoutState?.state?.status !== "paid"){
            setInterval(async ()=> {
                const orderService = new OrderService()
                const subscriptionService = new SubscriptionService()
                let response 
                if(checkoutState?.state?.order_id)
                    response = await orderService.get(`check/${checkoutState?.state?.order_id}`,)
                if(checkoutState?.state?.subscription_id)
                    response = await subscriptionService.get(`check/${checkoutState?.state?.subscription_id}`,)
                if(response?.status === "paid"){
                    if(productState?.product?.pixelSettings?.googleAds?.pushOnApprove){
                        dataLayerService.pushConversion({
                            transaction_id: checkoutState?.state?.order_id,
                            value: productState.productOffer?.price
                        }, productState?.product?.pixelSettings?.googleAds.map(setting => setting.pixelId))
                    }
                    setCheckoutState({
                        ...checkoutState,
                        step: 3,
                        state: {
                            ...checkoutState?.state,
                            status: response?.status
                        }
                    })

                    if(productState?.product?.approvedUrl){
                        let url = productState.product.approvedUrl
                            url = url.startsWith('http') ? url : `http://${url}`

                            let url_payload = {
                                product_name: productState?.product?.name,
                                name: formState?.name,
                                email: formState?.email,
                                cpf: formState?.cpf,
                                phone: formState?.phone,
                                zipcode: formState?.zipcode,
                                street: formState?.street,
                                neighborhood: formState?.neighborhood,
                                addressNumber: formState?.addressNumber,
                                city: formState?.city,
                                uf: formState?.uf,
                                ...formState?.customForm
                            }

                            for (const [key, value] of Object.entries(url_payload)) {
                                url_payload[key] = encodeURIComponent(value)
                            }

                            const redirect_url = `${url}?${new URLSearchParams(url_payload).toString()}`
                            // console.log('redirect_url', redirect_url)
                            window.location = redirect_url
                    }
                }
            }, 60000)
        }
    }, [checkoutState])

    const handleFormChange = async (field, value, required = true) => {
        setFormState({...formState, [field]: value})
        setValidator({...validator, ...formValidations(field, value, required)})
        if(field === "splitMonths"){
            quoteCart({selectedInstallment: value})
        }
    }

    const [cartSession, setCartSession] = useState(null)

    const handleCartSession = async (name, value) => {
        const regexEmail = /\S+@\S+\.\S+/;

        const formData = {...formState}
        delete formData["cardHolder"]
        delete formData["creditcard"]
        delete formData["expireMonth"]
        delete formData["expireYear"]
        delete formData["cvv"]

        if(name && value){
            formData[name] = value
        }

        const payload = formData
                    
        const metrics = getQueryVariableObject()
        if(metrics)
            payload['metrics'] = metrics

        
        const session = await new CartSessionService().post({id: cartSession?._id || null, email: payload.email, product: productState.product._id, offer: productState.productOffer._id, form: payload},'save', true)
        setCartSession(session)
        return session
        
    }



    const handleCustomFormChange = async (field, value, required = true) => {
        setFormState({...formState, customForm: {...formState.customForm, [field]: value}})
        setValidator({...validator, ...formValidations("custom_"+field, value, required, true)})
        //console.log(validator)
    }

    const renderCreditCardBrand = () => {
        switch(creditCardBrand){
            case "mastercard":
                return <InputGroup.Addon><img loading="lazy"src={mastercard} alt="mastercard" width="35px"/></InputGroup.Addon>
            case "visa":
                return <InputGroup.Addon><img loading="lazy"src={visa} alt="visa" width="35px"/></InputGroup.Addon>
            case "amex":
                return <InputGroup.Addon><img loading="lazy"src={amex} alt="american express" width="35px"/></InputGroup.Addon>
            case "diners":
                return <InputGroup.Addon><img loading="lazy"src={diners} alt="diners club" width="35px"/></InputGroup.Addon>
            case "elo":
                return <InputGroup.Addon><img loading="lazy"src={elo} alt="elo" width="35px"/></InputGroup.Addon>
            default:
                return null;
        }
    }


    // const getInstallmentsForProducts = () => {
        
    // }

    const toggleOrderBumpCheckbox = () => {
        const nextValue = !cartState.acceptedOrderBump
        if((productState?.product?.paymentType === "subscription" || productState?.orderBump?.paymentType === "subscription") && nextValue){
            setPaymentMethod('credit')
        }

        quoteCart({
            overrideOrderBumpState: true,
            zipCode: formState.zipcode,
            acceptedOrderBump: nextValue
        })
    }

    const parseFrequency = (frequency) => {
        switch(frequency){
            case "weekly":
                return [t('semanalmente'), t('semanas')];
            case "monthly":
                return [t('mensalmente'), t('meses')];
            case "bimonthly":
                return [t('a cada 2 meses'), t('bimestres')];
            case "quarterly":
                return [t('a cada 3 meses'), t('trimestres')];
            case "semiannual":
                return [t('a cada 6 meses'), t('semestres')];
            case "yearly":
                return [t('anualmente'), t('anos')];
            default: 
                return ''
        }
    }

    const getShippingServiceLogo = (service_id) => {
        switch (service_id) {
            case 1:
                return pac_correios
            case 2:
                return sedex_correios
            default:
                return null
        }
    }

    const getPaymentDiscount = (method) => {
        // if(productState?.product?.discountByPaymentMethod && productState?.product?.discountByPaymentMethod[method]?.enabled){
        //     return <small style={{color: "green"}}>{productState?.product?.discountByPaymentMethod[method]?.type === "percentage" ? `${productState?.product?.discountByPaymentMethod[method]?.discount}% OFF` : `R$ ${formatMoney(productState?.product?.discountByPaymentMethod[method]?.discount)} OFF`}</small> 
        // }
        return null
    }



    return  <Container>
                {
                    loading ? <div className="loading-payment">
                            <div className="lds-ripple"><div></div><div></div></div> 
                            <h5>{t('Processando pagamento, aguarde...')}</h5>
                        </div> : null
                }
                    <Content className="checkout-container">
                        <Row style={{display: 'flex', justifyContent: 'center', marginTop: "25px"}}>
                            <Col lg={10} sm={24}>
                                {
                                    productState?.product?.ownerId?.logoImage?.url ? <>
                                        <div >
                                            <img width={'120px'} src={productState?.product?.ownerId?.logoImage?.url} />
                                        </div>
                                    </> : null
                                }
                                {/* <Link to="/"></Link> */}
                            </Col>
                            <Col lg={14} sm={24}>
                                <Steps current={checkoutState.state.payment_method === "credit" || !checkoutState.state.payment_method ? checkoutState.step + 1 : checkoutState.step } className="only-desk">
                                    <Steps.Item title={t('Carrinho')} icon={<Icon icon="shopping-cart" size="lg" />} />
                                    <Steps.Item title={t('Pagamento')} icon={<Icon icon="credit-card-alt" size="lg" />}/>
                                    <Steps.Item title={t('Confirmação')} icon={<Icon icon="check" size="lg" />}/>
                                </Steps>
                            </Col>
                        </Row>
                    </Content>
                <Container>
                
                { 
                    checkoutState.step === 0 ? <Content className="checkout-container">
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Col sm={24} lg={14}> {/* Checkout form */}
                                <Card className="card-checkout">
                                    <Row className="formHeader" style={{marginBottom: '24px'}}>
                                        <Col lg={24}><h5>{t('Dados do')} {productState?.product?.paymentType === 'subscription' ? t('assinante') : t('comprador')}</h5></Col>
                                    </Row>

                                    <Row>
                                        <Col lg={24}>
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Nome completo')}</p>
                                            <InputGroup>
                                                <Input 
                                                size="lg"
                                                value={formState.name}
                                                onChange={(event) => handleFormChange("name", event, true)} />
                                            </InputGroup>
                                            <FieldValidator field="name" validator={validator} />
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '20px'}}>
                                        <Col lg={24}>
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('E-mail')}</p>
                                            <InputGroup>
                                                <Input 
                                                    value={formState.email} 
                                                    size="lg" 
                                                    onChange={(event) => handleFormChange("email", event, true)}
                                                    onBlur={(event) => handleCartSession()}
                                                />
                                            </InputGroup>
                                            <FieldValidator field="email" validator={validator} />
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '20px'}}>
                                        <Col lg={12}>
                                        <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('CPF')}</p>
                                            <InputMask
                                                value={formState.cpf}
                                                placeholder="000.000.000-00"
                                                className="rs-input rs-input-lg"
                                                mask={"999.999.999-999"}
                                                maskChar=""
                                                disabled={internationalResident}
                                                onChange={(event) => handleFormChange("cpf", event.target.value, true)}
                                            />
                                            <FieldValidator field="cpf" validator={validator} />
                                        </Col>
                                        <Col lg={12} className="mobile-fix-row-spacing">
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Celular com DDD')}</p>
                                            <InputMask
                                                value={formState.phone}
                                                style={{marginTop: '7px'}} placeholder={t('(99) 9 9999-9999')}
                                                className="rs-input rs-input-lg"
                                                mask={t('(99) 9 9999-9999')}
                                                maskChar=""
                                                onBlur={(event) => handleCartSession()}
                                                onChange={(event) => handleFormChange("phone", event.target.value, true)}
                                            />
                                            <FieldValidator field="phone" validator={validator} />
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '20px'}}>
                                        <Col lg={6}>
                                        <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('CEP')} <small className="label link" style={{fontSize: '.8em'}}><a href="https://buscacepinter.correios.com.br/app/endereco/index.php?t" target="_blank">{t('Não sei meu CEP')}</a></small> </p> 
                                            <InputMask
                                                style={{marginTop: '7px'}} placeholder="00000-000"
                                                className="rs-input rs-input-lg"
                                                value={formState.zipcode}
                                                disabled={addressFieldsDisabled.zipcode}
                                                mask={"99999-999"}
                                                maskChar=""
                                                onBlur={(event) => handleCartSession()}
                                                onChange={(event) => handleFormChange("zipcode", event.target.value, true)}
                                            />
                                            <FieldValidator field="zipcode" validator={validator} />
                                        </Col>
                                        <Col lg={15} className="mobile-fix-row-spacing">
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Endereço')} </p>
                                            <InputGroup>
                                                <Input disabled={addressFieldsDisabled.address} size="lg" value={formState.street} onChange={(event) => {handleFormChange("street", event, true)}} />
                                            </InputGroup>
                                        </Col>
                                        <Col lg={3} className="mobile-fix-row-spacing">
                                        <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Número')}</p>
                                            <InputGroup>
                                                <Input 
                                                onBlur={(event) => handleCartSession()}
                                                disabled={addressFieldsDisabled.number} type="number" value={formState.addressNumber} size="lg" onChange={(event) => {handleFormChange("addressNumber", event, true)}} />
                                            </InputGroup>
                                            { <FieldValidator field="addressNumber" validator={validator} />}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={24} className="mobile-fix-row-spacing">
                                            <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Complemento')} </p>
                                            <InputGroup>
                                                <Input disabled={addressFieldsDisabled.complement} size="lg" value={formState.complement} 
                                                onBlur={(event) => handleCartSession()}
                                                onChange={(event) => {handleFormChange("complement", event, true)}} />
                                            </InputGroup>
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '10px'}}>
                                        <Col lg={24} style={{display: 'flex', justifyContent: "flex-end"}}>
                                            <Checkbox onChange={toggleInternationalResident} checked={internationalResident}> {t('Sou residente no exterior')}</Checkbox>
                                        </Col>
                                    </Row>

                                    {
                                        productState?.product?.customFields && productState?.product?.customFields?.length > 0 ? <>
                                        <Row className="formHeader" style={{marginTop: '24px', marginBottom: '24px'}}>
                                            <Col lg={24}><h5>{t('Dados adicionais')}</h5></Col>
                                        </Row>

                                        <Row className="formHeader">
                                            {
                                                productState?.product?.customFields.map((customField, index) => {
                                                    return customField.fieldType !== "hidden" ? <Col lg={24} key={index} style={index > 0 ? {marginTop: '15px'} : {}}>
                                                        <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{customField.fieldName}</p>
                                                        {
                                                            customField.fieldType === "text" ? <InputGroup>
                                                                <Input size="lg"
                                                                    onBlur={(event) => handleCartSession()}
                                                                    // name={"custom_"+customField?.fieldUrlParam?.toLocaleLowerCase()}
                                                                    value={formState?.customForm ? formState?.customForm[customField?.fieldUrlParam?.toLocaleLowerCase()] : ''}
                                                                    onChange={(event)=> handleCustomFormChange(customField?.fieldUrlParam?.toLocaleLowerCase(), event, customField.required)}
                                                                />
                                                            </InputGroup> : null
                                                        }

                                                        {
                                                            customField.fieldType === "select_picker" ? <SelectPicker
                                                                size="lg"
                                                                cleanable={false}
                                                                searchable={false}
                                                                data={customField.options}
                                                                onBlur={(event) => handleCartSession()}
                                                                onChange={(event)=> handleCustomFormChange(sanitizeString(customField?.fieldName), event, customField.required)}
                                                                placeholder="Selecione"
                                                                style={{ width: '100%' }}
                                                            /> : null
                                                        }

                                                        {
                                                            customField.fieldType === "numeric" ? <InputGroup>
                                                                <Input type="number" size="lg"
                                                                    onBlur={(event) => handleCartSession()}
                                                                    value={formState?.customForm[customField?.fieldUrlParam?.toLocaleLowerCase()] ? formState?.customForm[customField?.fieldUrlParam?.toLocaleLowerCase()] : ``}
                                                                    onChange={(event)=> handleCustomFormChange(customField?.fieldUrlParam?.toLocaleLowerCase(), event, customField.required)}
                                                                />
                                                            </InputGroup> : null
                                                        }
                                                        <FieldValidator field={customField?.fieldUrlParam?.toLocaleLowerCase()} validator={validator} />
                                                    </Col> : <></>
                                                })
                                            }
                                        </Row>
                                        </> : null
                                    }

                                    {
                                        cartState?.quoteCart?.shippingOptions.length > 0 ? <>
                                            <Row style={{marginTop: '45px'}}>
                                                <Col lg={24}><h5>{t('Frete')}</h5></Col>
                                            </Row>

                                            <Row style={{marginTop: '45px'}}>
                                                <Col lg={24}><FieldValidator field="shipping" validator={validator} /></Col>
                                            </Row>

                                            {
                                                cartState?.quoteCart?.shippingOptions.length ? <RadioGroup
                                                name="radioList"
                                                value={cartState.selectedShippingId}
                                                onChange={value => toggleShippingMethod(value)}
                                            >
                                                {
                                                    cartState?.quoteCart?.shippingOptions.map((quote, index) => <Row key={index}>
                                                        <Radio appearance="picker"  value={quote?.id} style={{cursor: 'pointer'}} className={`shipping-method-option `}>
                                                            <Row style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                                                <Col style={{display: 'flex', justifyContent: 'flex-start'}} lg={5}>
                                                                    <Row>
                                                                        <Col lg={14}><img loading="lazy"className="shipping-logo" alt={quote?.name} src={getShippingServiceLogo(quote?.id)} /></Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <Row><Col lg={24}><strong>Correios</strong> <span style={{textTransform: 'capitalize'}}>{quote?.name}</span></Col></Row>
                                                                    <Row><Col lg={24} style={{marginTop: '5px'}}>{t('Chegará em até')} {quote.delivery_range?.max} {t('dias')}</Col></Row>
                                                                </Col>
                                                                <Col lg={5} style={{display: 'flex', justifyContent: 'flex-end', fontWeight: "bold", fontSize: "1.1em"}}>
                                                                    {  priceLoading ? <Loader /> : <> {quote?.price ? `R$${formatMoney(quote?.price)}` : 0}</>}
                                                                </Col>
                                                            </Row>
                                                        </Radio>
                                                        <hr></hr>
                                                    </Row>)
                                                }
                                            </RadioGroup> : <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '35px', marginBottom: '35px'}}>
                                                    <i class="fa-solid fa-circle-exclamation fa-2x"></i>
                                                    <h6 style={{marginLeft: '25px'}}>{t('Não existem opções de frete disponíveis para sua região')}</h6>
                                                </Col>
                                            </Row>
                                            }
                                            
                                            
                                            
                                        </> : null
                                    }

                                    <Row style={{marginTop: '45px'}}>
                                        <Col lg={24}><h5>{t('Forma de pagamento')}</h5></Col>
                                    </Row>

                                    { (productState?.product?.paymentType === "subscription" || productState?.orderBump?.paymentType === "subscription") && cartState.acceptedOrderBump ? <Row>
                                        <Col lg={24}>
                                            
                                            <Message
                                                showIcon
                                                type="info"
                                                title={t('Algumas formas de pagamento foram desabilitadas')}
                                                description={t('"Você selecionou uma oferta por assinatura, por esse motivo só será possível efetuar o pagamento no cartão de crédito"')}
                                            ></Message>
                                        </Col>
                                    </Row> : null }

                                    <Row style={{marginTop: '25px'}}>
                                        <Col lg={24}>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                { productState?.product?.payableWith.credit ? <div onClick={() => setPaymentMethod("credit")} style={{marginRight: '2%', cursor: 'pointer'}} className={`${paymentMethod === "credit" ? 'payment-method-selected shadow-md-selected' : ''} payment-method-option`}><i className="fas fa-credit-card fa-lg"></i> <span style={{marginLeft: '5px'}}>{t('Cartão')} {getPaymentDiscount("credit")}</span></div> : null}
                                                { productState?.product?.payableWith.pix && !(cartState.acceptedOrderBump && (productState?.product?.paymentType === "subscription" || productState?.orderBump?.paymentType === "subscription")) ? <div style={{cursor: 'pointer'}} onClick={() => setPaymentMethod("pix")} className={`${paymentMethod === "pix" ? 'payment-method-selected shadow-md-selected' : ''} payment-method-option `}><i className="fa-brands fa-pix  fa-lg"></i> <span style={{marginLeft: '5px'}}>{t('Pix')} {getPaymentDiscount("pix")}</span></div> : null}
                                                { productState?.product?.payableWith.boleto && !internationalResident && !(cartState.acceptedOrderBump && (productState?.product?.paymentType === "subscription" || productState?.orderBump?.paymentType === "subscription")) ? <div onClick={() => setPaymentMethod("boleto")} style={{marginLeft: '2%', cursor: 'pointer'}} className={`${paymentMethod === "boleto" ? 'payment-method-selected shadow-md-selected' : ''} payment-method-option `}><i className="fas fa-barcode fa-lg"></i> <span style={{marginLeft: '5px'}}>{t('Boleto')} {getPaymentDiscount("boleto")}</span></div> : null}
                                            </div>
                                        </Col>
                                    </Row>

                                    {
                                        paymentMethod === "credit" ? <>
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={24}>

                                                    <p className="credit-card-title">{t('Titular do Cartão de Crédito')}</p>
                                                    <InputGroup style={{maxHeight: '40px'}}>
                                                        <Input data-iugu="full_name" size="lg" type="text" onChange={(event) => handleFormChange("cardHolder", event, true)} />
                                                    </InputGroup>
                                                    <FieldValidator field="cardHolder" validator={validator} />
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={24}>

                                                    <p className="credit-card-title">{t('Número do Cartão de Crédito')}
                                                    
                                                    <Card className="card-credit-brands">    

                                                                <img loading="lazy"src={mastercard} alt="mastercard" width="35px" style={{marginRight: '10px'}}/>
                                                                <img loading="lazy"src={visa} alt="visa" width="35px" style={{marginRight: '10px'}}/>
                                                                <img loading="lazy"src={amex} alt="american express" width="35px" style={{marginRight: '10px'}}/>
                                                                <img loading="lazy"src={diners} alt="diners club" width="35px" style={{marginRight: '10px'}}/>
                                                                <img loading="lazy"src={elo} alt="elo" width="35px"/>
                                                            </Card>
                                                    </p>
                                                    <InputGroup style={{maxHeight: '40px'}}>
                                                        <Input data-iugu="number" size="lg" type="number" onChange={(event) => handleFormChange("creditcard", event, true)} />
                                                        
                                                            {
                                                                renderCreditCardBrand()
                                                            }
                                                        
                                                    </InputGroup>
                                                    <FieldValidator field="creditcard" validator={validator} />
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={9}>
                                                    <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Mês de Vencimento')}</p>
                                                    <SelectPicker
                                                        preventOverflow={true}
                                                        size="lg"
                                                        data={monthData}
                                                        cleanable={false}
                                                        searchable={false}
                                                        placeholder="Selecione o mês"
                                                        onChange={(event) => {handleFormChange("expireMonth", event, true)}}
                                                        style={{ width: '100%' }}
                                                    />
                                                    <FieldValidator field="expireMonth" validator={validator} />
                                                </Col>
                                                <Col lg={9} className="mobile-fix-row-spacing">
                                                    <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Ano de Vencimento')}</p>
                                                    <SelectPicker
                                                        preventOverflow={true}
                                                        size="lg"
                                                        data={yearData}
                                                        cleanable={false}
                                                        searchable={false}
                                                        placeholder="Selecione o ano"
                                                        onChange={(event) => {handleFormChange("expireYear", event, true)}}
                                                        style={{ width: '100%' }}
                                                    />
                                                    <Input type="hidden" data-iugu="expiration" value={`${formState.expireMonth||'00'}/${formState.expireYear||'0000'}`}></Input>
                                                    <FieldValidator field="expireYear" validator={validator} />
                                                </Col>
                                                <Col lg={6} className="mobile-fix-row-spacing">
                                                    <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('CVV')}</p>
                                                    <InputGroup>
                                                        <Input size="lg" 
                                                        data-iugu="verification_value"
                                                        type="number"
                                                        onChange={(event) => handleFormChange("cvv", event, true)} />
                                                    </InputGroup>
                                                    <FieldValidator field="cvv" validator={validator} />
                                                </Col>
                                                
                                            </Row>

                                            {
                                                productState?.product?.paymentType !== "subscription" ?  <Row style={{marginTop: '25px'}}>
                                                    <Col lg={24}>
                                                        <p style={{marginBottom: '6px', paddingLeft: '3px'}}>{t('Parcelas')}</p>
                                                        <SelectPicker
                                                            preventOverflow={true}
                                                            size="lg"
                                                            cleanable={false}
                                                            searchable={false}
                                                            onChange={(event) => {handleFormChange("splitMonths", event, true);}}
                                                            value={formState.splitMonths || 1}
                                                            // placeholder={productState.splitedMonths[0].label}
                                                            style={{ width: '100%' }}
                                                            data={cartState?.quoteCart?.installments}
                                                        />
                                                    <FieldValidator field="splitMonths" validator={validator} />
                                                    </Col>
                                                </Row> : null
                                            }
                                        </> : null
                                    }

                                    {
                                        paymentMethod === "pix" ? <> 
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={20}>
                                                    <Card>
                                                        <h5>{t('Informações sobre o pagamento via Pix:')}</h5>
                                                        <ul style={{marginTop: '25px'}}>
                                                            <li>{t('Geramos os dados de pagamento após você clicar em')} <b>{t('pagar agora')}</b>.</li>
                                                            <li>{t('Você pode pagar por QR Code ou Pix Copia e Cola.')}</li>
                                                            <li>{t('A liberação é feita em poucos minutos.')}</li>
                                                            <li>{t('Super seguro. O pagamento Pix foi desenvolvido pelo Banco Central para facilitar pagamentos.')}</li>
                                                        </ul>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </>: null
                                    }

                                    {
                                        paymentMethod === "boleto" ? <> 
                                            <Row style={{marginTop: '25px'}}>
                                                <Col lg={20}>
                                                    <Card>
                                                        <h5>{t('Informações sobre o pagamento via Boleto Bancário:')}</h5>
                                                        <ul style={{marginTop: '25px'}}>
                                                            <li>{t('Geramos o boleto e código de barras após você clicar em')} <b>{t('pagar agora')}</b>.</li>
                                                            <li>{t('Você pode pagar via internet banking, caixas bancários ou em casas lotéricas até o vencimento.')}</li>
                                                            <li>(t{'A liberação é feita de 1 a 3 dias úteis.'})</li>
                                                        </ul>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </>: null
                                    }

                                    {
                                        creditCardError.hasError ? <Row style={{marginTop: '15px'}} className="only-mobile">
                                            <Col lg={24}>
                                            <Message
                                                showIcon
                                                type="error"
                                                title={t('Falha no pagamento')}
                                                id="credit-card-error"
                                                description={<>
                                                <Row>
                                                    <Col lg={24}>
                                                        <p>
                                                            {creditCardError.message}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {
                                                    creditCardError.extra ? <>
                                                        <Row style={{marginTop: '5px'}}>
                                                            <Col lg={24}>
                                                                    <p>
                                                                        <span style={{fontWeight: 'bolder'}}> {t('Retorno do sistema')}:</span> { creditCardError.extra }
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                    </> : null
                                                }
                                                </>}
                                                />
                                            </Col>
                                        </Row> : null
                                    }

                                    <div className="only-mobile">
                                        {
                                            productState?.orderBump?.originOffer ? <OrderBump checkedState={cartState.acceptedOrderBump} toggleAction={toggleOrderBumpCheckbox} style={{marginTop: '25px'}} metadata={productState?.orderBump}></OrderBump> : null 
                                        }
                                    </div>

                                    <Row style={{marginTop: '15px'}} className="only-mobile">
                                        <Col lg={24}>
                                            <Button 
                                                onClick={formClick}
                                                color="green" 
                                                disabled={priceLoading}
                                                style={{borderRadius: 'unset', width: '100%', padding: '15px 0', fontSize: '1.2em', fontWeight: 'bold'}}>{t('PAGAR AGORA')}</Button>
                                        </Col>
                                    </Row>

                                    <Row style={{marginTop: '5px'}} className="only-mobile">
                                        <Col lg={24} style={{fontWeight: '500', color: 'rgba(0,0,0,0.5)'}} className="mobile-text-justify"><img loading="lazy"src={lockpick} width="15px" /> {t('Nós protegemos seus dados de pagamento usando criptografia para prover segurança ao nível bancário.')}</Col>
                                    </Row>
                                    
                                </Card>
                            </Col> {/* End checkout form */}
                            
                            
                            <Col sm={24} lg={10}> {/* Cart resume */}
                                {
                                    productState?.product?.productImage ? <Row style={{marginBottom: '24px'}}>
                                        <Col lg={24} sm={24}>
                                            <img loading="lazy" src={productState?.product?.productImage/*'https://picsum.photos/400/200'*/} width="400px" height="400px" alt={productState?.productOffer?.name} className="checkout-product-image"/>
                                        </Col>
                                    </Row> : null
                                }
                                
                                    {
                                        productState?.product?.enableChangeLanguage ? <div style={{display: 'flex', width:'100%%', justifyContent: 'flex-end', alignItems: 'center'}}>
                                            <small style={{margiRight: '15px'}}>{t('Selecione o idioma')}</small>
                                            <div onClick={()=>setLanguageByButton('pt')} style={{marginLeft: '10px', cursor: 'pointer'}}><img alt="Português do Brasil" src={br_flag} width="25px" /></div>
                                            <div onClick={()=>setLanguageByButton('en')} style={{marginLeft: '10px', cursor: 'pointer'}}><img alt="English" src={us_flag} width="28px" /></div>
                                            <div onClick={()=>setLanguageByButton('es')} style={{marginLeft: '10px', cursor: 'pointer'}}><img alt="Español" src={es_flag} width="25px" /></div>
                                        </div> : null
                                    }
                                    
                                <Card>
                                    <Row>
                                        <Col lg={24}>
                                            <h5>{t('Resumo da sua')} {productState?.product?.paymentType === 'subscription' ? t('assinatura') : t('compra')}</h5>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    {
                                        productState?.product?.showAllOffersInCheckout ? <>
                                            <Row>
                                                <Col lg={24}>
                                                    <h6>{productState?.product?.name}</h6>
                                                </Col>
                                            </Row>
                                        </> : null
                                    }
                                    {
                                        !productState?.product || !cartState?.quoteCart || cartState?.loading ? <>
                                        
                                        <Row>
                                        <Col lg={24}>
                                            <Row>
                                                <Col lg={20}>
                                                    <Row style={{fontWeight: 'bold'}}><Col lg={24}><Placeholder.Grid rows={1} columns={1} active /></Col></Row>
                                                    <Row><Col style={{marginTop: '10px', color: 'rgba(0,0,0,.4)'}} lg={24}><Placeholder.Grid rows={1} columns={1} active /></Col></Row>
                                                </Col>
                                                <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}><Placeholder.Grid rows={1} columns={1} active /></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={24} style={{textAlign: 'right',}}><Placeholder.Grid rows={1} columns={2} active /></Col>
                                    </Row></> : <>
                                        {
                                            productState?.product?.productType === "booking" ? <>
                                                <Row style={{marginTop: "15px"}}>
                                                    <div onClick={()=>!datePickerOpen ? setDatePickerOpen(true) : null} style={{border: "1px solid #ccc", width: "100%", minHeight: "45px", padding: "20px 30px", borderRadius: "8px", cursor: "pointer", display: "flex",justifyContent: "center", alignItems: "center"}}>
                                                        {
                                                            datePickerOpen ? <div>
                                                                <h5 style={{color: "rgba(0,0,0,.6"}}>Selecionar a data da reserva</h5>
                                                                <DayPicker
                                                                    locale={getDatePickerLocale()}
                                                                    mode="single"
                                                                    modifiersClassNames={{
                                                                        selected: 'calendar-selected',
                                                                        today: 'calendar-today'
                                                                    }}
                                                                    numberOfMonths={isMobile() ? 1 : 2}
                                                                    selected={formState?.bookDate}
                                                                    onSelect={(event) => {handleFormChange("bookDate", event, false); setDatePickerOpen(false)}}
                                                                    disabled={
                                                                        {
                                                                            before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                                                                        }
                                                                    }
                                                                />
                                                            </div> : <div onClick={()=>setDatePickerOpen(true)}>
                                                                <i class="fa-regular fa-calendar"></i>
                                                                <span style={{marginLeft: "15px", color: "rgba(0,0,0,.6"}}>
                                                                {
                                                                    formState?.bookDate ? dayjs(formState?.bookDate).format(t("DD/MM/YYYY")) : t("Selecionar data")
                                                                }
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <FieldValidator field="bookDate" validator={validator} />
                                                </Row>
                                            </> : null
                                        }
                                        {
                                            productState?.product?.enableFixedOffer ? <>
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg={24}>
                                                    <p style={{fontWeight: "600", marginBottom: "10px"}}>{productState?.product?.fixedOfferLabel}</p>
                                                    <SelectPicker 
                                                        size={"lg"}
                                                        searchable={false}
                                                        cleanable={false}
                                                        style={{width: '100%'}}
                                                        labelKey="name"
                                                        valueKey="id"
                                                        value={formState?.fixedOffer}
                                                        onSelect={(value) => {handleFixedOfferChange(value)}}
                                                        data={productState?.product?.fixedOffers.map(offer => {
                                                            return {
                                                                ...offer,
                                                                name: `${offer.name} - R$${offer.price > 0 ? formatMoney(offer.price) : "0,00"} ` + (productState?.product?.fixerOfferItemLabel ? `(${productState?.product?.fixerOfferItemLabel})` : ""),
                                                            }
                                                        })}
                                                    />
                                                    </Col>
                                                </Row>
                                                <FieldValidator field="fixedOffer" validator={validator} />
                                            </> : null
                                        }
                                    <Row style={{marginTop: "25px"}}>
                                        <Col lg={24}>
                                            {
                                                cartState?.quoteCart?.items?.map(item => {
                                                    return <Row style={{marginBottom: '10px'}}>
                                                        <Col lg={19} style={{height: "40px"}}>
                                                            <Row style={{marginTop: "5px", display: "flex", alignItems: "center"}}>
                                                                    { productState?.product?.allowChangeQuantity ? <Col lg={10}>
                                                                            <Button disabled={item.quantity <= 0} appearance="link" onClick={()=>decrementCartItemQuantity(item?.offer?._id)}><i class="fa-solid fa-minus" style={{cursor: "pointer"}}></i></Button>
                                                                            <span style={{color: 'rgba(0,0,0,0.4)', border: "1px solid rgba(0, 0, 0, 0.4)", padding: "7px", paddingRight: "10px", borderRadius: "6px", fontSize: "1.2em", fontWeight: 600}}> {item?.quantity}</span>
                                                                            <Button appearance="link" onClick={()=>incrementCartItemQuantity(item?.offer?._id)}><i class="fa-solid fa-plus" style={{cursor: "pointer"}}></i></Button>
                                                                    </Col> : null }
                                                                <Col lg={productState?.product?.allowChangeQuantity ? 14 : 24} style={{height: "100%"}}>
                                                                    <span style={{fontWeight: 'bold'}}>
                                                                        {capitalizeString(item?.offer?.name)}  
                                                                        {
                                                                            productState?.product?.allowChangeQuantity ? <> - <small style={{color: "rgba(0,0,0,.4)", fontWeight: 400}}>R${formatMoney(item?.offer?.price)} </small></> : null 
                                                                        }
                                                                        
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{fontWeight: 'light', fontStyle: 'italic'}}>
                                                                <Col lg={24}>
                                                                    <small>
                                                                        {productState?.product?.paymentType === "subscription" ? <>
                                                                            {t('pago')} {parseFrequency(item?.offer?.paymentFrequency)[0]}
                                                                            {!item?.offer?.automaticRenew ? `por um período de ${item?.offer?.subscriptionMaxOcurrency} ${parseFrequency(item?.offer?.paymentFrequency)[1]}` : ''}
                                                                        </> : <>
                                                                            {
                                                                                cartState?.acceptedOrderBump ? '' : '' //'pago uma única vez' : ''
                                                                            }
                                                                        </>}
                                                                    </small>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row><Col style={{marginTop: '10px', color: 'rgba(0,0,0,.4)'}} lg={24}>Aprenda a ser um programador muito bom com esse master class com vagas limitadas para dar um up na sua carreira.</Col></Row> */}
                                                        </Col>
                                                        <Col lg={5} style={{height: "40px", fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right', display: "flex", alignItems: "center", justifyContent: "flex-end"}}>R${item?.subtotal > 0 ? formatMoney(item?.subtotal) : "0,00"}</Col>
                                                    </Row>
                                                })
                                            }

                                            {
                                                cartState.quoteCart?.shipping ? <>
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg={19}>
                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>
                                                            <i className="fas fa-truck-fast fa-lg"></i> {t('Frete')} {cartState?.quoteCart?.shipping?.name }
                                                            <small>{t('Em até')} {cartState?.quoteCart?.shipping?.max} {t('dias úteis')}</small>
                                                            </Col></Row>
                                                    </Col>
                                                    {
                                                        cartState?.quoteCart?.discount > 0 && cartState?.quoteCart?.discountType === "free_shipping" ? <>
                                                        <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> 
                                                            <span style={{textDecoration: 'line-through'}}>R${formatMoney(cartState?.quoteCart?.shipping?.originalShippingPrice)}</span>
                                                            {
                                                                cartState?.quoteCart?.shipping?.price > 0 ? <span>R${formatMoney(cartState?.quoteCart?.shipping?.price)}</span> : <span>{t('Frete grátis')}</span>
                                                            }

                                                        </Col>
                                                        </> : <>
                                                        <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> R${formatMoney(cartState?.quoteCart?.shipping?.price)}</Col>
                                                        </>
                                                    }
                                                    
                                                </Row>
                                                </> : null
                                            }

                                            {
                                                cartState?.quoteCart?.discount > 0 && cartState?.quoteCart?.discountType !== "free_shipping" ? <>
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg={19}>
                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>{t('Desconto')} { cartState?.quoteCart?.discountType === "percentage" ? <span>({cartState?.quoteCart?.discountPercentage}%)</span> : null}</Col></Row>
                                                    </Col>
                                                    <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> 
                                                        R${formatMoney(cartState?.quoteCart?.discount)}
                                                    </Col>
                                                </Row>
                                                </> : null
                                            }

                                            {
                                                cartState?.quoteCart?.notEmbedTax > 0 ? <>
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg={19}>
                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>{t('Tarifa de conveniência')}</Col></Row>
                                                    </Col>
                                                    <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> R${formatMoney(cartState?.quoteCart?.notEmbedTax)}</Col>
                                                </Row>
                                                </> : null
                                            }

                                            {
                                                cartState?.quoteCart?.fixedOffer ? <>
                                                <Row style={{marginTop: '15px'}}>
                                                    <Col lg={19}>
                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>{productState?.product?.fixedOfferLabel} - { productState?.product?.fixedOffers?.find(offer => offer.id === formState?.fixedOffer)?.name}</Col></Row>
                                                    </Col>
                                                    <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> R${formatMoney(cartState?.quoteCart?.fixedOffersTotal)}</Col>
                                                </Row>
                                                </> : null
                                            }
                                            
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={24} style={{textAlign: 'right'}}>
                                            <span style={{color: 'rgba(0,0,0,.4)'}}>
                                                
                                                {
                                                    cartState?.quoteCart?.installments?.length && cartState?.quoteCart?.installments.find(item => item?.value === formState.splitMonths)?.value > 1 && paymentMethod === "credit" ? <span>
                                                    {
                                                        priceLoading ? null : <>
                                                            {t('Parcelado em')} { cartState?.quoteCart?.installments.length && cartState?.quoteCart?.installments.find(item => item?.value === formState?.splitMonths)?.value }x {t('de')} R${cartState?.quoteCart?.installments.find(item => item?.value === formState.splitMonths)?.installment} 
                                                        </>
                                                    }
                                                </span> : null
                                                }
                                            </span>
                                            <p></p>
                                            <Row style={{marginBottom: "10px"}}>
                                                <Col lg={24} style={{display: "flex", flexDirection: "row"}}>
                                                    {
                                                        appliedCoupom ? <>
                                                        <div style={{color: "#3498ff", border: "2px dashed #3498ff", width: 230, height: "35px", padding: "5px", fontSize: "1.1em", display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                                        <i style={{color: "#cecece",  marginLeft: "5px", marginRight: "10px"}} class="fa-solid fa-tag fa-lg"></i><small style={{ marginRight: "5px"}}> Cupom:</small>  {appliedCoupom}
                                                        </div>
                                                        <Button appearance="link" onClick={()=>{ 
                                                            setAppliedCoupom(null); 
                                                            handleFormChange("coupom", null, true)
                                                            quoteCart({coupom: "-1"}) //-1 is remove operation
                                                        }} style={{color: "#c3c3c3"}}>Remover</Button>
                                                        </> : <>
                                                            <InputGroup style={{width:230}}>
                                                                <InputGroup.Addon><i style={{color: "#3498ff"}} class="fa-solid fa-tag"></i></InputGroup.Addon>
                                                                <Input placeholder="Cupom de desconto"
                                                                    value={formState?.coupom}
                                                                    onChange={(event) => handleFormChange("coupom", event, true)}
                                                                />
                                                            </InputGroup>
                                                            <Button onClick={()=>{
                                                                setAppliedCoupom(formState?.coupom?.toUpperCase())
                                                                quoteCart({coupom: formState?.coupom})
                                                            }} style={{marginLeft: "5px"}} appearance="primary">
                                                                <i class="fa-solid fa-angle-right"></i>
                                                            </Button>
                                                        </>
                                                    }
                                                    
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            {
                                                cartState?.quoteCart?.message ? <>
                                                <Message style={{display: "flex", justifyContent: "flex-start"}} showIcon closable type="warning" description={cartState?.quoteCart?.message} />
                                                </> : null
                                            }
                                            { priceLoading ? <Loader /> : <span style={{fontWeight: 'bold', textAlign: 'left', fontSize: "1.14em"}}>{t('Total')} R${formatMoney(cartState?.quoteCart?.totalPrice)}</span> }</Col>
                                    </Row></>
                                    }
                                </Card>
                                <div className="only-desk">
                                    {
                                        productState?.orderBump?.originOffer ? <OrderBump checkedState={cartState.acceptedOrderBump} toggleAction={toggleOrderBumpCheckbox} style={{marginTop: '25px'}} metadata={productState?.orderBump}></OrderBump> : null 
                                    }
                                </div>

                                <Row style={{marginTop: '15px'}} className="only-desk">
                                    <Col lg={24}>
                                        <Button 
                                            onClick={formClick}
                                            color="green" 
                                            disabled={priceLoading}
                                            style={{borderRadius: 'unset', width: '100%', padding: '15px 0', fontSize: '1.2em', fontWeight: 'bold'}}>{t('PAGAR AGORA')}</Button>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: '5px'}} className="only-desk">
                                    <Col lg={24} style={{fontWeight: '500', color: 'rgba(0,0,0,0.5)'}}><img loading="lazy"src={lockpick} width="15px" />  {t('Nós protegemos seus dados de pagamento usando criptografia à nível bancário para prover segurança na compra.')}</Col>
                                </Row>

                                {
                                    creditCardError.hasError ? <Row style={{marginTop: '15px'}} className="only-desk">
                                        <Col lg={24}>
                                        <Message
                                                showIcon
                                                type="error"
                                                title={t('Falha no pagamento')}
                                                id="credit-card-error"
                                                description={<>
                                                <Row>
                                                    <Col lg={24}>
                                                        <p>
                                                            {creditCardError.message}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {
                                                    creditCardError.extra ? <>
                                                        <Row style={{marginTop: '5px'}}>
                                                            <Col lg={24}>
                                                                    <p>
                                                                        <span style={{fontWeight: 'bolder'}}> {t('Retorno do sistema')}:</span> { creditCardError.extra }
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                    </> : null
                                                }
                                                </>}
                                                />
                                        </Col>
                                    </Row> : null
                                }
                            </Col> {/* End cart resume */}
                        </Row> 
                    </Content> : null 
                }

                {
                    checkoutState.step === 1 ? <Content>
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Col lg={12} >
                                <Card>
                                {
                                        checkoutState.state.payment_method === "credit" ? <>
                                            <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center'}}>
                                                {
                                                    checkoutState.state.success ? <div className="animation-ctn">
                                                        <div className="icon icon--order-success svg">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                                                                    <g fill="none" stroke="#22AE73" stroke-width="2"> 
                                                                    <circle cx="77" cy="77" r="72" style={{strokeDasharray: '480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                    <polyline className="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{strokeDasharray:'100px, 100px', strokeDashoffset: '200px'}}/>   
                                                                    </g> 
                                                                </svg>
                                                                </div>
                                                        </div> : <div className="animation-ctn">
                                                            <div className="icon icon--order-success svg">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                                                                    <g fill="none" stroke="#F44812" stroke-width="2"> 
                                                                    <circle cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                    <circle id="colored" fill="#F44812" cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                    <polyline className="st0" stroke="#fff" stroke-width="10" points="43.5,77.8  112.2,77.8 " style={{strokeDasharray:'100px, 100px', strokeDashoffset: '200px'}}/>   
                                                                    </g> 
                                                                </svg>
                                                                </div>
                                                        </div> 
                                                }

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center', color: checkoutState.state.success ? 'rgb(56, 161, 105)' :  'rgb(255,99,71)'}}>
                                                    <h3>
                                                        {
                                                            checkoutState.state.success ? t('Pagamento aprovado') : t('Pagamento Recusado')
                                                        }
                                                    </h3>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{marginTop: '25px', display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    {
                                                        checkoutState.state.success ? 
                                                        <p style={{textAlign: 'center'}}>{t('O pagamento foi autorizado! Você receberá uma confirmação da compra por e-mail.')}</p> :
                                                        <p style={{textAlign: 'center'}}>{t('O pagamento foi negado pelo banco emissor, tente realizar o pagamento com outro cartão ou outra forma de pagamento.')}</p>
                                                    }
                                                </Col>
                                            </Row>
                                            {
                                                checkoutState.state.success ? <>
                                                    <Row>
                                                        <Col lg={24} style={{marginTop: '25px', display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                            <h5>{t('Resumo da sua compra')}</h5>
                                                        </Col>
                                                    </Row>

                                            
                                                    <Row style={{marginTop: "25px", display: "flex", justifyContent:"center"}}>
                                                        <Col lg={14}>
                                                            {
                                                                productState?.product?.productType === "booking" ? <>
                                                                <Row style={{marginTop: "15px"}}>
                                                                    <Col lg="24">Data da reserva: {parseDateTime(formState?.bookDate, "DD/MM/YYYY")}</Col>
                                                                </Row>
                                                            </> : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row style={{marginTop: "25px", display: "flex", justifyContent:"center"}}>
                                                        
                                                        <Col lg={14}>
                                                            {
                                                                cartState?.quoteCart?.items?.map(item => {
                                                                    return <Row style={{marginBottom: '10px'}}>
                                                                        <Col lg={19} style={{height: "40px"}}>
                                                                            <Row style={{marginTop: "5px", display: "flex", alignItems: "center"}}>
                                                                                <Col lg={productState?.product?.allowChangeQuantity ? 15 : 24} style={{height: "100%"}}>
                                                                                    <span>{item?.quantity}x </span>
                                                                                    <span style={{fontWeight: 'bold'}}>
                                                                                        {capitalizeString(item?.offer?.name)}  
                                                                                        
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row style={{fontWeight: 'light', fontStyle: 'italic'}}>
                                                                                <Col lg={24}>
                                                                                    <small>
                                                                                        {productState?.product?.paymentType === "subscription" ? <>
                                                                                            {t('pago')} {parseFrequency(item?.offer?.paymentFrequency)[0]}
                                                                                            {!item?.offer?.automaticRenew ? `por um período de ${item?.offer?.subscriptionMaxOcurrency} ${parseFrequency(item?.offer?.paymentFrequency)[1]}` : ''}
                                                                                        </> : <>
                                                                                            {
                                                                                                cartState?.acceptedOrderBump ? '' : '' //'pago uma única vez' : ''
                                                                                            }
                                                                                        </>}
                                                                                    </small>
                                                                                </Col>
                                                                            </Row>
                                                                            {/* <Row><Col style={{marginTop: '10px', color: 'rgba(0,0,0,.4)'}} lg={24}>Aprenda a ser um programador muito bom com esse master class com vagas limitadas para dar um up na sua carreira.</Col></Row> */}
                                                                        </Col>
                                                                        <Col lg={5} style={{height: "40px", fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right', display: "flex", alignItems: "center", justifyContent: "flex-end"}}>R${item?.subtotal > 0 ? formatMoney(item?.subtotal) : "0,00"}</Col>
                                                                    </Row>
                                                                })
                                                            }

                                                            {
                                                                cartState.quoteCart?.shipping ? <>
                                                                <Row style={{marginTop: '15px'}}>
                                                                    <Col lg={19}>
                                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>
                                                                            <i className="fas fa-truck-fast fa-lg"></i> {t('Frete')} {cartState?.quoteCart?.shipping?.name }
                                                                            <small>{t('Em até')} {cartState?.quoteCart?.shipping?.max} {t('dias úteis')}</small>
                                                                            </Col></Row>
                                                                    </Col>
                                                                    {
                                                                        cartState?.quoteCart?.discount > 0 && cartState?.quoteCart?.discountType === "free_shipping" ? <>
                                                                        <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> 
                                                                            <span style={{textDecoration: 'line-through'}}>R${formatMoney(cartState?.quoteCart?.shipping?.originalShippingPrice)}</span>
                                                                            {
                                                                                cartState?.quoteCart?.shipping?.price > 0 ? <span>R${formatMoney(cartState?.quoteCart?.shipping?.price)}</span> : <span>{t('Frete grátis')}</span>
                                                                            }

                                                                        </Col>
                                                                        </> : <>
                                                                        <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> R${formatMoney(cartState?.quoteCart?.shipping?.price)}</Col>
                                                                        </>
                                                                    }
                                                                    
                                                                </Row>
                                                                </> : null
                                                            }

                                                            {
                                                                cartState?.quoteCart?.discount > 0 && cartState?.quoteCart?.discountType !== "free_shipping" ? <>
                                                                <Row style={{marginTop: '15px'}}>
                                                                    <Col lg={19}>
                                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>{t('Desconto')} { cartState?.quoteCart?.discountType === "percentage" ? <span>({cartState?.quoteCart?.discountPercentage}%)</span> : null}</Col></Row>
                                                                    </Col>
                                                                    <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> 
                                                                        R${formatMoney(cartState?.quoteCart?.discount)}
                                                                    </Col>
                                                                </Row>
                                                                </> : null
                                                            }

                                                            {
                                                                cartState?.quoteCart?.notEmbedTax > 0 ? <>
                                                                <Row style={{marginTop: '15px'}}>
                                                                    <Col lg={19}>
                                                                        <Row style={{fontWeight: 'bold'}}><Col lg={24}>{t('Tarifa de conveniência')}</Col></Row>
                                                                    </Col>
                                                                    <Col lg={5} style={{fontWeight: 'bold', fontSize: '1.1em', textAlign: 'right'}}> R${formatMoney(cartState?.quoteCart?.notEmbedTax)}</Col>
                                                                </Row>
                                                                </> : null
                                                            }
                                                            
                                                        </Col>
                                                    </Row>
                                                    <hr></hr>
                                                    <Row>
                                                        <Col lg={24} style={{textAlign: 'right'}}>
                                                            <span style={{color: 'rgba(0,0,0,.4)'}}>
                                                                
                                                                {
                                                                    cartState?.quoteCart?.installments?.length && cartState?.quoteCart?.installments.find(item => item?.value === formState.splitMonths)?.value > 1 && paymentMethod === "credit" ? <span>
                                                                    {
                                                                        priceLoading ? null : <>
                                                                            {t('Parcelado em')} { cartState?.quoteCart?.installments.length && cartState?.quoteCart?.installments.find(item => item?.value === formState?.splitMonths)?.value }x {t('de')} R${cartState?.quoteCart?.installments.find(item => item?.value === formState.splitMonths)?.installment} 
                                                                        </>
                                                                    }
                                                                </span> : null
                                                                }
                                                            </span>
                                                            
                                                            { priceLoading ? <Loader /> : <span style={{fontWeight: 'bold', textAlign: 'left', fontSize: "1.14em"}}>{t('Total')} R${formatMoney(cartState?.quoteCart?.totalPrice)}</span> }</Col>
                                                    </Row>

                                                </> : null 
                                            }

                                            
                                        </> : null
                                    }

                                    {
                                        checkoutState.state.payment_method === "pix" && checkoutState.state.status === "qr_code_created" ? <>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"width="200px" src={pix_grande} alt={t('Pagamento por Pix')}></img>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <h3 style={{color: 'rgb(56, 161, 105)'}}>{t('Pedido efetuado! Agora finalize o pagamento')}</h3>
                                                </Col>
                                            </Row>

                                            {/* <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <h3 style={{color: 'rgb(237, 137, 54)'}}>Pagamento em análise</h3>
                                                </Col>
                                            </Row> */}

                                            <Row>
                                                <Col lg={24} style={{ display: 'flex', justifyContent: 'center', fontSize: '1.2em', height: '100px'}}>
                                                    <Loader size="lg" />
                                                </Col>
                                            </Row>
                                            

                                            <Row>
                                                <Col lg={24} style={{marginTop: '25px', display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    <ol type="1">
                                                        <li>{t('Abra o app do seu banco e entre na opção Pix')}.</li>
                                                        <li>{t('Escolha a opção')} <b>{t('Pagar Pix copia e cola')}</b> {t('ou')} <b>{t('Scanear QR Code')}</b></li>
                                                        <li>{t('Realize o pagamento')}</li>
                                                        <li>{t('A confirmação é feita em poucos minutos, essa tela')} <br /> {t('atualizará automaticamente')}.</li>
                                                    </ol>
                                                </Col>
                                            </Row>

                                            {/* <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    A confirmação é feita em poucos minutos
                                                </Col>
                                            </Row> */}

                                            <Row>
                                                <Col lg={24} style={{marginTop: '25px', display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"width="300px" src={checkoutState.state.qrcode} alt="QR Code Pix" />
                                                </Col>
                                            </Row>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={16}>
                                                    <InputGroup>
                                                        <Input 
                                                            disabled
                                                            size="lg" 
                                                            value={checkoutState.state.qrcode_text} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <Button className="clipboardButton" onClick={() => {copyToClipBoard(checkoutState.state.qrcode_text)}} appearance="ghost">
                                                        <Icon icon="copy"/> {t('COPIAR CÓDIGO PIX')}
                                                    </Button>
                                                </Col>
                                            </Row>

                                            {/* <div style={{border: '1px solid rgba(0,0,0,.1)', padding: '24px 15px'}}>
                                                <Row>
                                                    <Col lg={24} style={{display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                        A confirmação é feita em poucos minutos
                                                    </Col>
                                                </Row>
                                                <Row style={{marginTop: '25px', display: 'flex', justifyContent: 'center'}}>
                                                    <Col lg={24} style={{textAlign: 'center', fontSize: '1.2em'}}>
                                                        Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.
                                                    </Col>
                                                </Row>
                                            </div> */}

                                            

                                            {/* <Row>
                                                <Col lg={24} style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <Button appearance="link" onClick={handleClickVerifyPaymentPix}>JÁ EFETUEI O PAGAMENTO</Button>
                                                </Col>
                                            </Row> */}
                                            <hr></hr>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={10}>
                                                    <h5>{t('TOTAL')}</h5>
                                                </Col>
                                                <Col lg={10} style={{textAlign: 'right'}}>
                                                    <h5>R${formatMoney(cartState?.quoteCart?.totalPrice)}</h5>
                                                </Col>
                                            </Row>
                                        </> : null
                                    }

                                    {
                                        checkoutState.state.payment_method === "boleto" ? <>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"width="150px" src={boleto_grande} alt="Pagamento por Boleto Bancário"></img>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <h3 style={{color: 'rgb(56, 161, 105)'}}>{t('Pedido gerado! Agora finalize o pagamento')}</h3>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{marginTop: '25px', display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    <ul>
                                                        <li>{t('Pague pelo seu internet banking, lotérica ou direto na agência bancária')}.</li>
                                                        <li>{t('Pagável até a data do vencimento')}</li>
                                                    </ul>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    {t('A confirmação é feita em até 3 dias úteis')}
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <a className="rs-btn rs-btn-ghost" href={`https://faturas.iugu.com/${checkoutState?.state?.secure_id}.pdf`} target="_blank">
                                                        <Icon icon="print" /> {t('IMPRIMIR BOLETO')}
                                                    </a>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{ display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"src={checkoutState.state.barcode} alt="Código de Barras" />
                                                </Col>
                                            </Row>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={16} >
                                                    <InputGroup>
                                                        <Input 
                                                            disabled
                                                            size="lg" 
                                                            value={checkoutState.state.digitable_line} />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <Button className="clipboardButton" onClick={() => copyToClipBoard(checkoutState.state.digitable_line)}appearance="ghost">
                                                        <Icon icon="copy"/> {t('COPIAR CÓDIGO DE BARRAS')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={10}>
                                                    <h5>{t('TOTAL')}</h5>
                                                </Col>
                                                <Col lg={10} style={{textAlign: 'right'}}>
                                                    <h5>R${formatMoney(cartState?.quoteCart?.totalPrice)}</h5>
                                                </Col>
                                            </Row>
                                        </> : null
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Content> : null
                }

                {
                    checkoutState.step === 2 ? <Content>
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Col lg={12} >
                                <Card>
                                    {
                                        checkoutState.state.payment_method === "pix" && checkoutState.state.status === "qr_code_created" ? <>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"width="200px" src={pix_grande} alt={t('Pagamento por Pix')}></img>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <h3 style={{color: 'rgb(237, 137, 54)'}}>{t('Pagamento em análise')}</h3>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg={24} style={{ display: 'flex', justifyContent: 'center', fontSize: '1.2em', height: '100px'}}>
                                                    <Loader size="lg" />
                                                </Col>
                                            </Row>
                                            <div style={{border: '1px solid rgba(0,0,0,.1)', padding: '24px 15px'}}>
                                            <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    {t('A confirmação é feita em poucos minutos')}
                                                </Col>
                                            </Row>
                                            <Row style={{marginTop: '25px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={24} style={{textAlign: 'center', fontSize: '1.2em'}}>
                                                    {t('Quando o pagamento for identificado, a tela será atualizada automaticamente e você também receberá uma confirmação por e-mail.')}
                                                </Col>
                                            </Row>
                                            </div>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <p style={{textAlign: 'center', fontSize: '1.2em'}}>{t('Ainda não fez o pagamento?')} <Button appearance="link" onClick={handleClickVerifyPaymentPix}> {t('Efetuar Pagamento')}</Button></p>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={10}>
                                                    <h5>{t('TOTAL')}</h5>
                                                </Col>
                                                <Col lg={10} style={{textAlign: 'right'}}>
                                                    <h5>R${formatMoney(cartState?.quoteCart?.totalPrice)}</h5>
                                                </Col>
                                            </Row>
                                        </> : null
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Content> : null
                }

                {
                    checkoutState.step === 3 ? <Content>
                        <Row style={{display: 'flex', justifyContent: 'center'}}>
                            <Col lg={12} >
                                <Card>
                                    {
                                        checkoutState.state.payment_method === "pix" && checkoutState.state.status === "paid" ? <>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <img loading="lazy"width="200px" src={pix_grande} alt="Pagamento por Pix"></img>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <div className="animation-ctn">
                                                        <div className="icon icon--order-success svg">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">  
                                                                <g fill="none" stroke="#22AE73" stroke-width="2"> 
                                                                <circle cx="77" cy="77" r="72" style={{strokeDasharray: '480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{strokeDasharray:'480px, 480px', strokeDashoffset: '960px'}}></circle>
                                                                <polyline className="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{strokeDasharray:'100px, 100px', strokeDashoffset: '200px'}}/>   
                                                                </g> 
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>



                                            <Row>
                                                <Col lg={24} style={{margin: '25px 5px', display: 'flex', justifyContent: 'center'}}>
                                                    <h3 style={{color: 'rgb(56, 161, 105)'}}>{t('Pagamento aprovado')}!</h3>
                                                </Col>
                                            </Row>

                                            
                                            <div style={{border: '1px solid rgba(0,0,0,.1)', padding: '24px 15px'}}>
                                            <Row>
                                                <Col lg={24} style={{display: 'flex', justifyContent: 'center', fontSize: '1.2em'}}>
                                                    {t('Seu pagamento via Pix foi recebido com sucesso, um e-mail com detalhes da sua compra foi enviado.')}
                                                </Col>
                                            </Row>
                                            
                                            </div>
                                            <hr></hr>
                                            <Row style={{margin: '5px 5px', display: 'flex', justifyContent: 'center'}}>
                                                <Col lg={10}>
                                                    <h5>{t('TOTAL')}</h5>
                                                </Col>
                                                <Col lg={10} style={{textAlign: 'right'}}>
                                                    <h5>R${formatMoney(cartState?.quoteCart?.totalPrice)}</h5>
                                                </Col>
                                            </Row>
                                        </> : null
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Content> : null
                }
                </Container>

                <Footer style={{marginTop: '25px', marginBottom: '25px'}}>
                        <Row style={{background: 'white', width: '100%', display: 'flex', justifyContent: 'center'}} className="checkout-header">
                            <Col lg={22}>
                                <Row style={{display: 'flex', justifyContent: 'center'}} className="mobile-direction-column">
                                    <Col lg={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="mobile-footer-item">
                                        <img loading="lazy"alt="D4Pay" width="110px" src={logo} />
                                    </Col>
                                    <Col lg={2}>
                                    </Col>
                                    <Col lg={6} style={{textAlign: 'center'}}>
                                        {t('Formas de Pagamento')}
                                        <Row style={{marginTop: '15px'}} className="mobile-footer-item">
                                            <img loading="lazy"src={mastercard} alt="mastercard" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={visa} alt="visa" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={amex} alt="american express" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={diners} alt="diners club" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={elo} alt="elo" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={boleto} alt="elo" width="35px" style={{marginRight: '10px'}}/>
                                            <img loading="lazy"src={pix} alt="elo" width="35px"/>
                                        </Row>
                                    </Col>
                                    <Col lg={2}>
                                    </Col>
                                    <Col lg={5} style={{textAlign: 'center'}} className="mobile-footer-item">
                                        {t('Segurança')}
                                        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px'}}>
                                            <a href="https://safeweb.norton.com/report/show?url=D4Pay.app" target="_blank"><img loading="lazy"src={norton} width={'80px'} alt=""/></a>
                                            <a href="https://transparencyreport.google.com/safe-browsing/search?url=D4Pay.app&hl=pt_BR" target="_blank"><img loading="lazy"src={googlesafe} width={'80px'} alt="Google Safe Browsing"/></a>
                                            <a href="https://www.sslshopper.com/ssl-checker.html#hostname=D4Pay.app" target="_blank"><img loading="lazy"src={sslshopper} width={'80px'} alt=""/></a>
                                        </Row>
                                        
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                </Footer>
            </Container>
}

export default CheckoutPage;
