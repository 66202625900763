import { formatInTimeZone } from 'date-fns-tz';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

import dayjs from 'dayjs';

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

require('dayjs/locale/pt-br')

export function formatDate(data, formatStr) {
    return format(data, formatStr, { locale: ptBR });
}

export default function parseDateTime(date, formatStr = 'DD/MM/YYYY HH:mm') {
    if(!date) return '';
    // const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());
    // if (!isValidDate(date)) {
    //     console.error('Invalid date', date);
    //     return '';
    // }
    // return format(new Date(date), formatStr, { locale: ptBR });
    return dayjs(date).tz('America/Sao_Paulo').locale('pt-br').format(formatStr);
}