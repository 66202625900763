import BaseService from './base/base.service'
import AuthService from './Authentication.service';

export default class ProductService extends BaseService {

    constructor(){
        super('product')
    }

    async getAllUserProducts(){
        return await this.get('list/withoffers/simple')
    }
}