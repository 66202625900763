const generateArrayOfYears = () => {
    const max = new Date().getFullYear()
    const min = max + 15
    const years = []

    for (var i = max; i <= min; i++) {
        years.push({label: String(i), value: i})
    }
    return years
}

export default generateArrayOfYears;