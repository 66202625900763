import React from 'react';
import { ErrorMessage } from "rsuite"

const FieldValidator = (props) => {

    let hasError = false
    if(props.validator[props.field])
        hasError = props.validator[props.field]['hasError']

    let message = ""
    if(props.validator[props.field])
        message = props.validator[props.field].message

    return <ErrorMessage style={{color: props.color || 'red'}} show={hasError} placement='topStart'>
        {message}
    </ErrorMessage>
}

export default FieldValidator