
import { Notification } from 'rsuite';


class CepService{

  async find(cep){
    try{
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        "method": "GET",
      })
      const json = await response.json();
      if(json.error){
        return false;
      }

      return json;
    }catch(e){
      return {
        erro: "Failed to find cep"
      };
    }
  }

}

export default CepService;