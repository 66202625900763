
import React, {useState} from 'react'
import { Checkbox, CheckboxGroup, Button } from 'rsuite';


import orderbump_arrow from '../assets/svg/orderbump_arrow.svg'
import { Tag } from 'rsuite/lib';
import { Col } from 'rsuite/lib';
import { Row } from 'rsuite/lib';

const OrderBump = (props) => {
    const { metadata } = props

    const [acceptOrderBump, setAcceptOrderBump] = useState(false);

    const toggleCheckbox = () => {
        setAcceptOrderBump(!acceptOrderBump)
    }

    return <>
    {
        metadata?.orderBumpImage ? <>
            <Row style={{marginTop: "25px"}}>
                <Col lg={24}>
                    <h4>Separamos esta oferta para você!</h4>
                </Col>
            </Row>
            <Row style={{marginTop: "15px", marginBottom: "25px"}}>
                <Col lg={24}>
                    <div className="orderBump-card" style={(acceptOrderBump || props.checkedState) ? {border: "3px #4caf50d6 dashed"} : {} }>
                        <div style={{borderRadius: "8px", backgroundSize: "cover", backgroundImage: `url('${metadata?.orderBumpImage}')`, width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                            <div style={{height: "fit-content",  display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                <Tag className="orderbump-tag payment-status-approved"><i style={{color: "red"}} class="fa-solid fa-fire"></i><span style={{marginLeft: '5px'}}> {metadata.description}</span></Tag>
                            </div>
                            <div style={{backgroundImage: "linear-gradient(0deg, rgba(0,0,0,0.8) 1%, transparent)", borderRadius: "8px"}}>
                                <div className="orderbump-content">
                                    <div className="orderbump-title">
                                        <h3 className="orderBump-card-title">
                                            {metadata?.callToAction}
                                        </h3>
                                        <p style={{ color: "white", fontWeight: 500, textShadow: "black -1px 4px 7px"}}>{metadata.title} - R${`${metadata.offer ? metadata.offer.price.toFixed(2).replace(".", ",") : "XX,XX"}`}</p>
                                    </div>
                                    <div style={{ width: "25%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <h3 className="orderBump-card-title">
                                            <Button style={(acceptOrderBump || props.checkedState) ? {backgroundColor: "#4caf5091", fontWeight: 600} : {fontWeight: 600}} onClick={props.previewMode ? toggleCheckbox : props.toggleAction } appearance='primary' color="green">{(acceptOrderBump || props.checkedState) ?  <><i style={{marginRight: "3px"}} class="fa-solid fa-check"></i>Adicionado</> : "Aceitar oferta"}</Button>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </> : <>
            <div className="orderBump-border" onClick={props.previewMode ? toggleCheckbox : props.toggleAction } style={{...props.style}}>
                <div className="orderBump-header">
                    {metadata?.callToAction}
                </div>
                <div className="orderBump-body">
                    <div className="orderBump-arrow">
                        <img src={orderbump_arrow} width="45px"/>
                    </div>
                    <div className="orderBump-offer">
                        <Checkbox checked={props.previewMode ? acceptOrderBump : props.checkedState}></Checkbox>
                        <p><span style={{color: 'red', textDecoration: 'underline'}}>{metadata.title}:</span> {metadata.description} - R${`${metadata.offer ? metadata.offer.price.toFixed(2).replace(".", ",") : "XX,XX"}`}</p>
                    </div>
                </div>
            </div>
        </>
    }
    </>
    
    
}

export default OrderBump;