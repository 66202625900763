import React from 'react';

import {
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";

import './App.css';
import './spinner.css';
import 'rsuite/dist/styles/rsuite-default.css';
import './theme.css'

import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';

// import { formatDate } from './utils/parseDateTime'

import PrivateRoute from "./components/PrivateRoute.component"

import loadable from '@loadable/component'

import CheckoutPage from "./pages/Checkout/Checkout.page"

const HomePage = loadable(() => import( './pages/Home.page'));
const PasswordRecoveryPage = loadable(() => import( "./pages/PasswordRecovery/PasswordRecovery.page"));
const LoginPage = loadable(() => import( './pages/Login/Login.page'));
// const AdminPage = loadable(() => import( './pages/Admin/Admin.page'));
const RegisterPage = loadable(() => import( './pages/Register/Register.page'));
const AuthenticatedTemplate = loadable(() => import( './templates/Authenticated.template'));
const ProductsPage = loadable(() => import( "./pages/Product/Product.page"));
const AddProductPage = loadable(() => import( "./pages/Product/AddProduct.page"));
const MembersAreaPage = loadable(() => import( "./pages/MembersArea/MembersArea.page"));
const AddCoursePage = loadable(() => import( "./pages/MembersArea/AddCourse.page"));
const NotFoundPage = loadable(() => import( "./pages/NotFound/NotFound.page"));
const IntegrationsPage = loadable(() => import( './pages/Integrations/Integrations.page'));
const WebhooksPage = loadable(() => import( "./pages/Integrations/subpages/Webhooks.page"));
const NotificationsPage = loadable(() => import( "./pages/Integrations/subpages/Notifications.page"));
const OrderPage = loadable(() => import( './pages/Order/Order.page'));
const SubscriptionPage = loadable(() => import( './pages/Subscription/Subscription.page'));
const ClubManagerPage = loadable(() => import( './pages/Subscription/ClubManager.page'));
const ClubPage = loadable(() => import( './pages/Subscription/Club.page'));
const BoardSettingsPage = loadable(() => import( './pages/Subscription/BoardSettings.page'));
const FinancePàge = loadable(() => import( './pages/Finance/Finance.page'));
const ReportsPage = loadable(() => import( "./pages/Reports/Reports.page"));
const AdminCategoriesPage = loadable(() => import( "./pages/Admin/Categories.page"));
const MyAccountPage = loadable(() => import( "./pages/MyAccount/MyAccount.page"));
const MyOrdersPage = loadable(() => import( "./pages/MyOrders/MyOrders.page"));
const AffiliatePage = loadable(() => import( "./pages/Affiliates/Affiliates.page"));
const AffiliateMarketplacePage = loadable(() => import( "./pages/Affiliates/AffiliateMarketplace.page"));
const SchoolPage = loadable(() => import( './pages/MembersArea/School.page'));
const AddSchoolPage = loadable(() => import( './pages/MembersArea/AddSchool.page'));
const AddCoupomPage = loadable(() => import( './pages/Coupom/AddCoupom.page'));
const CoupomPage = loadable(() => import( './pages/Coupom/Coupom.page'));


export default function App() {
  return (
    <HashRouter basename="/">
      <div>
        <IntlProvider locale={ptBR}>
          <Switch>
            <Route exact={true} path="/checkout/:shortUrl">
              <CheckoutPage />
            </Route>
            <Route exact={true} path="/checkout/:shortUrl/ref/:affiliateCode">
              <CheckoutPage />
            </Route>
            <Route exact={true} path="/register">
              <RegisterPage />
            </Route>
            <Route exact={true} path="/404">
              <NotFoundPage />
            </Route>
            <Route exact={true} path="/login">
              <LoginPage />
            </Route>
            <Route path="/forgot/:recoveryToken">
              <PasswordRecoveryPage />
            </Route>
            <Route exact={true} path="/forgot">
              <PasswordRecoveryPage />
            </Route>
            <PrivateRoute path="/integrations" exact={true}>
              <AuthenticatedTemplate>
                <IntegrationsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/integrations/webhooks" exact={true}>
              <AuthenticatedTemplate>
                <WebhooksPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/integrations/notifications" exact={true}>
              <AuthenticatedTemplate>
                <NotificationsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            {/* <PrivateRoute path="/admin/:entity/edit/:id">
              <AuthenticatedTemplate>
                <AdminPage action="edit" />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            {/* <PrivateRoute path="/admin/:entity/create">
              <AuthenticatedTemplate>
                <AdminPage action="create"/>
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            {/* <PrivateRoute path="/admin/:entity">
              <AuthenticatedTemplate>
                <AdminPage action="list" />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            <PrivateRoute path="/product/create">
              <AuthenticatedTemplate>
                <AddProductPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/product/edit/:id">
              <AuthenticatedTemplate>
                <AddProductPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/product">
              <AuthenticatedTemplate>
                <ProductsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/coupom/create">
              <AuthenticatedTemplate>
                <AddCoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/coupom/edit/:id">
              <AuthenticatedTemplate>
                <AddCoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/coupom">
              <AuthenticatedTemplate>
                <CoupomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={false} path="/members">
              <AuthenticatedTemplate>
                <MembersAreaPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools">
              <AuthenticatedTemplate>
                <SchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/add/">
              <AuthenticatedTemplate>
                <AddSchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/schools/edit/:id">
              <AuthenticatedTemplate>
                <AddSchoolPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            {/* <PrivateRoute exact={true} path="/course/add">
              <AuthenticatedTemplate>
                <AddCoursePage />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            <PrivateRoute exact={true} path="/course/:id">
              <AuthenticatedTemplate>
                <AddCoursePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/orders">
              <AuthenticatedTemplate>
                <OrderPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/orders/find/voucher/:voucher">
              <AuthenticatedTemplate>
                <OrderPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/subscriptions">
              <AuthenticatedTemplate>
                <SubscriptionPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/subscriptions/manager">
              <AuthenticatedTemplate>
                <ClubManagerPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/subscriptions/club/config">
              <AuthenticatedTemplate>
                <ClubPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            {/* <PrivateRoute exact={true} path="/subscriptions/manager/club/config">
              <AuthenticatedTemplate>
                <BoardSettingsPage />
              </AuthenticatedTemplate>
            </PrivateRoute> */}
            <PrivateRoute exact={true} path="/subscriptions/club/config/:id">
              <AuthenticatedTemplate>
                <BoardSettingsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/finance">
              <AuthenticatedTemplate>
                <FinancePàge />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/reports">
              <AuthenticatedTemplate>
                <ReportsPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/dashboard">
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/account">
              <AuthenticatedTemplate>
                <MyAccountPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/myorders">
              <AuthenticatedTemplate>
                <MyOrdersPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/affiliates">
              <AuthenticatedTemplate>
                <AffiliatePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/affiliate/invite/:id">
              <AuthenticatedTemplate>
                <AffiliateMarketplacePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <Route path="/active/:activationToken">
              <LoginPage activation="true" />
            </Route>
            <PrivateRoute path="/" exact={true}>
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>

            {/* start Admin routes */}
              <PrivateRoute path="/admin/products/edit/:id">
                <AuthenticatedTemplate>
                  <AddProductPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/products">
                <AuthenticatedTemplate>
                  <ProductsPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/orders">
                <AuthenticatedTemplate>
                  <OrderPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/subscriptions">
                <AuthenticatedTemplate>
                  <SubscriptionPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
              <PrivateRoute exact={true} path="/admin/categories">
                <AuthenticatedTemplate>
                  <AdminCategoriesPage isAdmin={true} />
                </AuthenticatedTemplate>
              </PrivateRoute>
            {/* end Admin routes */}

            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
        </IntlProvider>
      </div>
    </HashRouter>
  );
}
